@import "./variables";
.aside {
  background: $light !important;
  .aside-toggler {
    transition: $transition;
    &.active {
      transform: rotate(180deg);
      transform-origin: 50% 50%;
    }
  }
  .aside-menu-wrapper {
    .aside-menu {
      background: $themePrimaryColor;
      ul {
        padding-top: 10svh;
        li {
          a {
            span {
              color: $light !important;
              font-size: "initial";
              img {
                filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(293deg) brightness(106%) contrast(300%);
              }
            }
            &:hover {
              background: $gray !important;
            }
            &.active {
              background: $gray !important;
              border: 1px solid $themePrimaryColor;
              span {
                color: $themePrimaryColor !important;
                img {
                  filter: invert(56%) sepia(98%) saturate(1635%) hue-rotate(173deg) brightness(90%) contrast(90%);
                }
              }
            }
          }
        }
      }
    }
  }
}
