@import "./variables";
@import "./auth";
@import "./header";
@import "./sideBars";
@import "./drawers";
@import "./forms";
@import "./cards";

body {
  background: $themeColor;
  color: $textPrimaryColor;
}
.offcanvas-overlay {
  display: none;
}

.action-bar {
  margin: 0 0 1rem;
  padding: 1rem 2rem;
  margin-top: -2rem;
  margin-left: -2rem;
  width: calc(100% + 3.9rem);
  background: $light;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 991px) {
    margin-top: -1rem;
    width: calc(100% + 3rem);

    // width: 100%;
  }
  .btn {
    padding: 0.15rem 1rem !important;
    margin-right: 1rem;
    font-size: 1.1rem;
    border-radius: 100px;
    height: 30px;
    display: flex;
    align-items: center;

    &:disabled {
      border-color: rgba($textPrimaryColor, 0.25) !important;
      color: rgba($textPrimaryColor, 0.5) !important;
      &:hover {
        background: none !important;
      }
    }
    &[class*="-primary"] {
      &:hover {
        color: $light;
        background-color: $themePrimaryColor;
      }
      &:focus {
        color: $themePrimaryColor !important;
        background-color: $light !important;
      }
      &.active {
        color: $light !important;
        background-color: $themePrimaryColor !important;
      }
    }
    &[class*="-danger"] {
      &:hover {
        // color: $light !important;
        background-color: $colorRed;
      }
      &:focus {
        color: $colorRed !important;
        background-color: $light !important;
      }
    }
  }
  select,
  .btn-select {
    background: $light;
    border: 1px solid $themePrimaryColor;
    color: $themePrimaryColor;
    border-radius: 100px;
    padding: 0.25rem 1rem;
    img {
      filter: invert(56%) sepia(98%) saturate(1635%) hue-rotate(173deg) brightness(90%) contrast(90%);
    }
    &:hover {
      color: $themePrimaryColor !important;
    }
  }
  .form-control {
    width: 100%;
    max-width: 500px;
  }
}

.btn-bar {
  display: flex;
  justify-content: center;
  .btn-light {
    display: none;
  }
}

.btn {
  text-align: center;
  text-transform: capitalize !important;
  // &.btn-sm {
  //   padding: 0.2rem 0.5rem !important;
  //
  // }
  &.btn-pri {
    background: $themePrimaryColor !important;
    color: $light;
    border: 1px solid $themePrimaryColor;
  }
  &.btn-sec {
    background: $light !important;
    color: $themePrimaryColor;
    border: 1px solid $themePrimaryColor;
  }
  &.btn-white {
    background: $light;
    border: 1px solid #595d6e;
  }
  &.btn-red {
    background: #ed553b;
    border: 0;
  }
  &.btn-gray {
    background: rgba(32, 99, 155, 0.1);
    border-radius: 3px;
    color: $themePrimaryColor;
    font-weight: 500;
    padding: 0.5rem 1.5rem !important;
    &:hover {
      color: $themePrimaryColor !important;
    }
  }
  // &.btn-select {
  //     background: rgba(32, 99, 155, 0.1) url(../img/icon-select.svg) center
  //         right no-repeat;
  // }
  &.btn-rounded {
    border-radius: 100px !important;
  }
  &.btn-upload {
    overflow: hidden;
    position: relative;
    input {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  &:hover {
    color: $light !important;
    box-shadow: $boxShadowHoverBase;
  }
}

// TAGS

.tags-wrapper {
  .tag {
    font-weight: 500;
    padding: 0.25rem 1.5rem !important;
    border-radius: 5px;
    float: left;
    position: relative;
    border: 0 !important;
    background: #e4ecf3;
    .tag-action {
      position: absolute;
      top: -0.3rem;
      right: -0.3rem;
      border-radius: 100px;
      background: #e4ecf3;
      width: 13px;
      height: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 7px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &.tag-gray {
      background: rgba(32, 99, 155, 0.1);
      color: $themePrimaryColor;
      margin-left: 8%;
    }
  }
}

/* COLORS */
.color-primary {
  color: rgba($textPrimaryColor, 0.7);
}
.color-primary-light {
  color: rgba($textPrimaryColor, 0.7);
}

.card {
  background: $light;
  border-radius: 14.53px;
  padding: 1.25rem 1.5rem;
  margin-bottom: 1.25rem !important;
  .card-title {
    .title-text {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1;
      margin: 0;
    }
    .dashboard-header {
      font-weight: bold;
      font-size: 21px;
    }
    .dashboard-info {
      font-weight: 600;
    }
    small {
      padding-top: 5px;
      color: #828282;
      line-height: 18px;
      font-size: 14px;
    }
  }
  &.card-visual {
    min-height: 330px;
    @media screen and (max-width: 1700px) {
      min-height: 270px;
    }
    .graph-block {
      display: flex;
      .graph-img {
        width: 100%;
        .img-wrap {
          max-width: 300px;
          min-width: 200px;
          margin: 0 auto;
        }
      }
      .graph-detail {
        width: 100%;
        max-width: 300px;
        ul {
          padding-left: 1.5rem;
          li {
            margin-bottom: 1rem;
            small {
              font-size: 12px;
              line-height: 1.5;
              font-weight: bold;
            }
            b {
              font-weight: 600;
              font-size: 1.5rem;
              display: block;
              line-height: 1;
            }
            &.color-green {
              &::marker {
                color: $colorGreen;
              }
            }
            &.color-orange {
              &::marker {
                color: $colorOrange;
              }
            }
            &.color-purple {
              &::marker {
                color: $colorPurple;
              }
            }
            &.color-red {
              &::marker {
                color: $colorRed;
              }
            }
            &.color-gray {
              &::marker {
                color: $colorGray;
              }
            }
          }
        }
      }
    }
  }
  &.card-trend-line {
    .card-body {
      display: flex;
      .trendline-chart {
        min-width: 85%;
        // .p-chart {
        //   min-height: 300px;
        //   canvas {
        //     min-height: 300px;
        //   }
        // }
      }
      .trendline-action {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        min-width: 200px;
        .btn {
          margin: 0 auto 2rem !important;
          width: 100%;
          max-width: 100px;
        }
      }
      @media screen and (max-width: 1280px) {
        flex-direction: column;
        .trendline-chart {
          min-width: 100%;
        }
        .trendline-action {
          display: block;
          margin: 1rem 0;
          .btn {
            margin: 0.5rem 1rem !important;
            max-width: auto !important;
            width: auto;
            padding-left: 1rem;
            padding-right: 1rem;
          }
        }
      }
    }
  }
}

/* SECTIONS */
.section-title {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #eef0f8;
  border-bottom: 2px solid #20639b;
  padding: 0.5rem 1.1rem;
  .title-text {
    font-size: 1.1rem;
    font-weight: 500;
    color: $textSecondaryColor;
  }
}
.company-section-title {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 2px solid #bdbdbd;
  padding: 0.5rem 0.5rem;
  .title-text {
    font-size: 13px;
    font-weight: 700;
    color: $textSecondaryColor;
  }
}
.img-company-logo {
  border: 2px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
  img {
    width: auto;
    height: 178px;
  }
}

// TABLE

.table {
  &.table-user {
    color: $textSecondaryColor;
    margin-top: 2rem;
    border-radius: 5px;
    overflow: hidden;
    thead {
      th {
        background: #f3f6f9;
        border-bottom: 2px solid #173f5f;
        padding: 0.5rem 0;
      }
    }
    tbody {
      td {
        border: 0;
        padding: 0.5rem 0;
      }
    }
    td {
      vertical-align: middle;
    }
    .col-avatar {
      .avatar-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 50px;
        min-width: 50px;
        height: 50px;
        overflow: hidden;
        border-radius: 100px;
        img {
          height: 50px;
          width: auto;
        }
      }
    }
    .col-user-detail {
      b {
        display: block;
        width: 100%;
      }
      small {
        display: block;
        width: 100%;
        color: $textLightColor;
      }
    }
    .col-status {
      padding-bottom: 58px;

      &.user-disabled {
        color: #eb5757;
      }
      &.user-enabled {
        color: #54b8ae;
      }
    }
  }
}

.forget-password-next {
  background-color: white;
  height: 100%;
  .image {
    p {
      font-size: 21px;
      color: #828282;
      padding-left: 15rem;
      padding-top: 15rem;
    }
  }
  .text {
    padding-top: 15rem;
    h6 {
      padding-left: 10rem;
      padding-top: 50px;
    }
  }
  button {
    width: auto;
    padding-left: 25%;
  }
}
.notifications {
  overflow-y: auto;
  height: 100%;
  .unread-no {
    padding-left: 28px;
    padding-bottom: 24px;
    font-weight: 500;
    b {
      font-weight: 700;
    }
  }
  .notifications-list {
    margin-bottom: 10px;
  }
  .detail-text {
    color: #495057;
  }
  .notification-detail {
    border-bottom: 1px solid #f2f2f2;
  }
  .invoice-unread {
    font-weight: 700;
  }
  .invoice-read {
    font-weight: 500;
  }
}

// MATERIAL CUSTOM

.MuiFormControl-root {
  .MuiFormLabel-root {
  }
  &:focus-within {
    label {
      color: $themePrimaryColor;
    }
    .MuiInputBase-root {
      &:after {
        border-bottom-color: $themePrimaryColor;
      }
    }
  }
  .MuiInputBase-root {
    &:hover {
      &:not(.Mui-disabled) {
        &:before {
          border-bottom-color: $themePrimaryColor !important;
        }
      }
    }
  }
}
.remove-arrows {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #2d9cdb !important;
}

.ribbon-label {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  width: 11rem;
  height: 22px;
  left: 230px;
  top: 30px;
  position: absolute;
  transform: rotate(45deg);
  background: #eb5757;
  color: white;
}
.invoice-status-ribbon {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  width: 11.9rem;
  height: 22px;
  left: 226px;
  top: 26px;
  position: absolute;
  transform: rotate(45deg);
  background: #6fcf97;
  color: white;
  z-index: 1;
}
.truncate-160 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 160px;
  max-width: 160px;
}
.truncate-180 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 180px;
  max-width: 180px;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 220px;
  max-width: 220px;
}

.payment-type-active {
  color: #3caea3;
}
.payment-type-inActive {
  color: #eb5757;
}
.payment-types {
  display: flex;
  justify-content: space-between;
}
.company-space-between {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  span {
    text-align: left;
    min-width: 23rem;
  }
}
.company-button {
  border-radius: 3px !important;
}
.company-users-list {
  border-bottom: 1px solid #e0e0e0;
}
.subscription-label {
  border-bottom: 5px solid #bdbdbd;
  padding-left: 26px;
  padding-right: 26px;
  color: #828282;
}
.label-border-color {
  border-bottom: 5px solid #2d9cdb !important;
}
.subscription-buttons {
  text-align: center;
  border: 1px solid #828282;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  h2 {
    color: #828282;
  }
  .button-margin-top {
    margin-top: 4.5rem;
  }
}

.new-subscription {
  p {
    color: #828282;
    font-size: 15px;
  }
  span {
    color: #828282;
    font-size: 15px;
  }
  b {
    color: #828282;
    font-size: 15px;
  }
  .span-summary {
    width: 133px;
  }
}
.subscription-users {
  padding: 9px;
  width: 34px;
  color: #2b8ec6;
  border-radius: 5px;
  border: 1px solid #828282;
}

.heading-color {
  color: #333333 !important;
  font-weight: 500 !important;
}
.sub-heading-color {
  color: #828282 !important;
  font-weight: 400;
  font-size: 14px;
}
.default-payment {
  color: #828282 !important;
  font-size: 12px;
}

.refund-dialog {
  width: 150px;
  max-width: 180px;
  display: flex;
  justify-content: space-between;
  .refunded-quantity {
    font-size: 30px;
    text-align: center;
  }
  button {
    background-color: #f2f2f2;
  }
}

.data-in-field {
  z-index: 1;
  width: auto;
  right: 10px;
  float: right;
  cursor: pointer;
  margin-top: -21px;
  position: relative;
}
.product-color-shapes {
  // width: 2em;
  // height: 2em;
  width: 40px;
  height: 40px;
  @media screen and (max-width: 529px) {
    width: 30px;
    height: 30px;
  }
}
.product-text-colors {
  width: 1.5em;
  height: 2em;
  font-size: 30px;
  @media screen and (max-width: 529px) {
    font-size: 20px;
  }
}
.product-color-empty-spaces {
  width: 1.5em;
  height: 2em;
  @media screen and (max-width: 437px) {
    width: 0.5em;
  }
}
.product-text-colors-wrap {
  gap: 1.2rem;
  @media screen and (max-width: 529px) {
    gap: 1.6rem;
  }
  @media screen and (max-width: 437px) {
    gap: 0.4rem;
  }
}
.pos-colors-preview-wrap {
  margin-top: 0px;
  @media screen and (max-width: 529px) {
    margin-top: 1.25rem;
  }
}

.pos-prefix {
  font-size: 12px;
  float: left;
  line-height: 1;
}

.user-active {
  margin-top: -18px;
}
.homePage-wrapper {
  background-color: #ffffff;
}
.pos-view {
  position: relative;
  background: url("../../assets/img/qrDasboard.svg") no-repeat center center fixed;
  background-size: cover;
}

.pos-view::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(234.19, 241.36, 245.44, 0.9);
}
.started-header {
  background: #f0f0f0;
  background: url("../../assets/img/EasyToolsImg.svg") no-repeat center center fixed;
  background-size: cover;
}
.laptop-border {
  background: #f0f0f0;
  background: url("../../assets/img/laptopBorder.svg");
  background-size: cover;
  height: 333px;
}
.cards-details {
  margin: 60px 0;
}

.header-text {
  font-weight: 500;
  font-size: 16px;
  color: "#4F4F4F";
}
.home-card {
  margin: 0 32px 43px 32px;
  gap: 20px;
  height: 271px;
  width: 233px !important;

  background-color: #fcfcfc;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px #00000040;
}
.bussiness-card {
  border-radius: 10px;
  p {
    font-size: 30px;
    line-height: 40px;
  }
}
.card-img {
  margin: 12px 0;
  box-shadow: 0px 4px 15px 0px #00000040;
}
.pos-view-wrapper {
  padding: 200px 18px;
  h2 {
    font-size: 60px;
  }
  h3 {
    font-size: 30px;
  }
}
.bussiness-card-wrapper {
  margin-top: 60px;
}
.tool-text {
  padding: 10px 0;
}
.footer-wrapper1 {
  display: flex !important;
}
.img-wrapper {
  width: 500px;
}
.laptopImg {
  width: 100%;
}
.home-card {
  margin: 0 15px 15px 15px;
  width: 100%;

  @media (min-width: 576px) {
    width: 48%;
  }

  @media (min-width: 768px) {
    width: 48%;
  }

  @media (min-width: 992px) {
    width: 48%;
  }

  @media (min-width: 1200px) {
    width: 48%;
  }
}
.header-features {
  display: flex;
}
@media screen and (max-width: 1200px) {
  .header-features {
    display: none !important;
  }
  .login-btn {
    display: none;
  }
  .phoneIcon {
    display: none;
  }
  .img-wrapper {
    width: 400px;
  }
  .laptop-img-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .pos-view-wrapper {
    h2 {
      font-size: 50px;
    }
    h3 {
      font-size: 27px;
    }
  }
  .bussiness-card-wrapper {
    margin-top: 10px;
  }

  .tool-text {
    font-size: 17px !important;
    padding: 20px 0;
  }
  .img-wrapper {
    width: 230px;
  }
}
@media screen and (max-width: 778px) {
  .bussiness-card-wrapper {
    padding: 0 15px;
  }
  .pos-wrapper {
    .media_links {
      img {
        border-radius: 12px;
        width: 98% !important;
      }
    }
  }
  .bussiness-card {
    p {
      font-size: 20px;
      line-height: 35px;
    }
  }
}
@media screen and (max-width: 620px) {
  .bussiness-card {
    p {
      font-size: 20px;
      line-height: 30px;
    }
  }
  .pos-view-wrapper {
    h2 {
      font-size: 30px;
    }
    h3 {
      font-size: 25px;
    }
  }
}

@media screen and (max-width: 658px) {
  .footer-input {
    margin-left: 1px !important;
  }
}

@media screen and (max-width: 992px) {
  .pos-view-wrapper {
    padding: 0 30px;
  }
  .laptop-images {
    margin-top: 30px;
  }
}
@media screen and (max-width: 1060px) {
  .newsLetter {
    .media_links {
      display: none;
    }
    .footer-input {
      margin-left: auto;
    }
  }
  .footer-wrapper1 {
    display: none !important;
  }
}

@media screen and (max-width: 553px) {
  .cards-details {
    .container {
      padding: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .home-card {
      width: 219px !important;
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  .bussiness-card {
    p {
      font-size: 14px;
      line-height: 17px;
    }
  }
  .pos-view-wrapper {
    h2 {
      font-size: 28px;
    }
    h3 {
      font-size: 22px;
    }
  }
}
@media screen and (max-width: 500px) {
  .cards-details {
    .home-card {
      height: 170px;
      width: 147px !important;
      margin-left: 16px;
      margin-right: 16px;
      .homeCard-img {
        width: 75px !important;
        height: 75px !important;
      }
      p {
        font-size: 13px !important;
        line-height: 16px !important;
      }
    }
  }
  .bussiness-card {
    p {
      font-size: 12px;
      line-height: 15px;
    }
  }
  .pos-view-wrapper {
    h2 {
      font-size: 25px;
    }
    h3 {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 453px) {
  .home-logo {
    width: 104px !important;
  }
}
.analytics-hovar:hover {
  background-color: #e9e9e9 !important;
}

.test {
  .react-bootstrap-daterangepicker-container {
    display: block !important;
  }

  .form-control {
    border-radius: unset;
    border-color: rgba(224, 224, 224, 1) !important;
  }
  img {
    position: absolute;
    left: 25px;
    top: 53px;
    width: 15px;
    height: 15px;
  }
}

.test-filter {
  .react-bootstrap-daterangepicker-container {
    display: block !important;
  }

  .form-control {
    border-radius: unset;
    border-color: rgba(224, 224, 224, 1) !important;
    padding-right: 4rem;
  }
  img {
    position: absolute;
    left: 25px;
    top: 80px;
    width: 15px;
    height: 15px;
  }
}
.store-icon {
  .store {
    position: absolute;
    left: 25px;
    top: 100px;
    width: 15px;
    height: 15px;
    z-index: 1;
  }
  .store-control {
    border-radius: unset !important;
    border-color: rgba(224, 224, 224, 1) !important;
    .select-icon {
      margin-left: 12px !important;
      margin-right: 12px !important;
    }
    .store-control {
      .store__value-container {
        color: rgba(51, 51, 51, 1) !important;
      }
    }
    .store__clear-indicator {
      display: none !important;
    }
    .store__indicators {
      .store__indicator-separator {
        display: none !important;
      }
    }
    .store__indicator {
      svg {
        color: rgba(51, 51, 51, 1) !important;
      }
    }
  }
}
.user-icon {
  .person {
    position: absolute;
    left: 25px;
    top: 148px;
    width: 15px;
    height: 15px;
    z-index: 1;
  }
  .user-control {
    border-radius: unset !important;
    border-color: rgba(224, 224, 224, 1) !important;
    .select-icon {
      margin-left: 12px !important;
      margin-right: 12px !important;
    }
    .user-control {
      .user__value-container {
        color: rgba(51, 51, 51, 1) !important;
      }
    }
    .user__indicators {
      .user__indicator-separator {
        display: none !important;
      }
    }
    .user__clear-indicator {
      display: none !important;
    }
    .user__indicator {
      svg {
        color: rgba(51, 51, 51, 1) !important;
      }
    }
  }
}
.card {
  .card-body {
    .table {
      thead {
        tr {
          color: rgba(130, 130, 130, 1) !important;
        }
      }
    }
  }
}
.main-content {
  .row {
    .apexcharts-xaxis-label {
      display: none !important;
    }
    .apexcharts-yaxis-texts-g {
      display: none !important;
    }
  }
}
.d-flex {
  .side-menu {
    height: auto !important;
  }
}
.row {
  .col-sm-6 {
    .card {
      .card-body {
        .apexcharts-legend {
          display: none;
        }
      }
    }
  }
  .col-sm-6 {
    .product-card {
      height: 368px !important;
      .card-body {
        margin-top: 46px;
        padding: 0 !important;
        .top-products-list {
          list-style: none;
          padding: 0;
          margin: 0;
          .top-product-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            padding: 8px 0;
            font-size: 16px;
          }
          .product-dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 10px;
          }

          .product-name {
            flex: 1;
            display: flex;
            align-items: center;
            font-size: 14px;
          }

          .product-price {
            font-weight: bold;
          }

          .top-product-item:nth-child(1) .product-dot {
            background-color: rgba(242, 153, 74, 1);
          }

          .top-product-item:nth-child(2) .product-dot {
            background-color: rgba(47, 128, 237, 1);
          }

          .top-product-item:nth-child(3) .product-dot {
            background-color: rgba(155, 81, 224, 1);
          }

          .top-product-item:nth-child(4) .product-dot {
            background-color: rgba(111, 207, 151, 1);
          }

          .top-product-item:nth-child(5) .product-dot {
            background-color: rgba(235, 87, 87, 1);
          }
        }
      }
    }
  }
}
.table-responsive {
  .table {
    thead {
      white-space: nowrap;
      tr {
        color: rgba(130, 130, 130, 1) !important;
        font-weight: 700;
        font-size: 14px;
        line-height: 15.37px;
        svg {
          color: rgba(0, 0, 0, 1) !important;
        }
        th:last-child {
          // text-align: end;
        }
      }
    }
    .table-margin {
      margin-top: 20px;
    }
  }
  tbody {
    white-space: nowrap;
    .no-data-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      text-align: center;
      gap: 30px;
      .table-icons {
        .file-icon {
          position: relative;
          right: 95px;
        }
      }
    }
    .tr-content {
      .td-col:last-child {
        // text-align: end;
      }
      tr {
        td {
          font-weight: 400;
          font-size: 14px;
          line-height: 16.56px;
          color: rgba(51, 51, 51, 1) !important;
        }
        .last-child:last-child {
          // text-align: end;
        }
      }
      .table-contnet {
        .table-content {
          .table-icons {
            .elipse-icon {
              width: 150px;
              height: 150px;
            }
            .file-icon {
              width: 50px;
              height: 64px;
              position: relative;
            }
          }
        }
      }
    }
  }
}
.disc-card {
  .side-menu {
    height: 867px !important;
  }
}
.caregory-card {
  .side-menu {
    height: 867px !important;
  }
}
.payment-channel-card {
  .side-menu {
    height: 867px !important;
  }
  .main-content {
    .card {
      .card-title {
        font-size: 14px !important;
      }
    }
  }
}
.tax-card {
  .side-menu {
    height: 867px !important;
  }
  .main-content {
    .small-card {
      .card-custom {
        .info-title {
          font-size: 14px;
        }
      }
    }
    .info-cards {
      margin-left: -12px;
      display: flex;
      .net-sale-card {
        margin-left: 10px;
      }
      // .small-card{
      //   // width: 460px;
      // }
      // width: 460px !important;
    }
  }
}

@media screen and (max-width: 500px) {
  .tax-card {
    .side-menu {
      height: 867px !important;
    }
    .main-content {
      .small-card {
        .card-custom {
          .info-title {
            font-size: 14px;
          }
        }
      }
      .info-cards {
        margin-left: -12px;
        display: flex;
        flex-direction: column !important;
        .net-sale-card {
          margin-left: 10px;
        }
        // .small-card{
        //   // width: 460px;
        // }
        // width: 460px !important;
      }
    }
  }
}
.small-card {
  .card-custom {
    .card-body {
      .amount-value {
        display: flex;
        justify-content: center;
        font-size: 30px;
        font-weight: 500;
      }
    }
  }
}
.side-menu {
  .reset-btn {
    font-size: 14px !important;
    font-weight: 500;
    line-height: 15.92px;
  }
  &.full-height {
    height: 876px !important;
  }
}
.row {
  .col-sm-6 {
    .chart-card {
      height: 368px !important;
      .card-body {
        .product-img-section {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 30px;
          .elipse-icon {
            width: 150px;
            height: 150px;
          }
          .file-icon {
            width: 50px;
            height: 64px;
            position: absolute;
            bottom: 180px;
            right: 278px;
          }
        }
      }
      h3 {
        font-weight: 400;
        font-size: 14px;
        line-height: 15.77px;
      }
    }
    .product-card {
      h3 {
        font-weight: 400;
        font-size: 14px;
        line-height: 15.77px;
      }
      .card-body {
        .product-img-section {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 30px;
          .elipse-icon {
            width: 150px;
            height: 150px;
          }
          .file-icon {
            width: 50px;
            height: 64px;
            position: absolute;
            bottom: 160px;
            right: 284px;
          }
        }
        .top-products-list {
          .product-name {
            font-weight: 500;
            font-size: 14px;
            line-height: 25.77px;
            letter-spacing: 1.19px;
          }
          .product-price {
            font-weight: 500;
            font-size: 14px !important;
            line-height: 25.77px;
          }
        }
      }
    }
  }
  .col-sm-12 {
    .card {
      .card-body {
        padding: 0 !important;
        .card-title {
          font-size: 14px !important;
        }
        .table-header {
          h5 {
            margin-left: 9px;
          }
          span {
            .filter-dialog {
              background: white;
              border: 1px solid #ccc;
              border-radius: 4px;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
              padding: 20px;
              z-index: 1000;
              width: 240px !important;
            }

            .filter-dialog-content {
              position: relative;
            }

            .filter-dialog h3 {
              margin-top: 0;
            }

            .filter-dialog ul {
              list-style: none;
              padding: 0;
              margin: 0;
            }

            .filter-dialog ul li {
              margin-bottom: 10px;
            }

            .filter-dialog button {
              position: absolute;
              top: 10px;
              right: 10px;
              background: none;
              border: none;
              cursor: pointer;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
.main-content {
  .tax-card {
    height: auto !important;
    .card-body {
      padding: 0 !important;
      .card-title {
        font-size: 14px !important;
      }
    }
  }
}
.disc-card {
  .discount-main {
    .discount-card {
      .discount-cardbody {
        padding: 0 !important;
        height: auto !important;
        .card-title {
          font-size: 14px !important;
        }
      }
    }
  }
}
.page-navigation {
  .main-container {
    display: flex;
    align-items: center;
    gap: 10px;
    .btn-section {
      display: flex;
      align-items: center;
      border: 1px solid #ccc;
      border-radius: 4px;
      overflow: hidden;
      .btn-left {
        height: 30px;
        width: 60px;
        border: none;
        background: white;
        color: #333;
      }
      .btn-right {
        height: 30px;
        width: 60px;
        border: none;
        background: white;
        color: #333;
      }
    }
    .page-number {
      width: 30px;
      height: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    .limit-select {
      height: 30px;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 0 10px;
    }
  }
}
.sales-summary-card {
  .card {
    .reset-btn {
      font-size: 14px !important;
    }
    .card-body {
      padding: 0;
      .card-title {
        font-size: 14px !important;
      }
    }
  }
  .main-content {
    .row {
      .card-content {
        .card-bodys {
          .d-flex {
            .font-weight-bold {
              font-weight: 500;
              font-size: 14px !important;
            }
            .font-weight-bolder {
              font-size: 22px;
              font-weight: 500 !important;
            }
          }
        }
      }
    }
    .wide-card-chart {
      .card-bodys {
        .font-weight-bolder {
          font-size: 14px !important;
        }
        .wide-chart-prevamount {
          font-weight: 400 !important;
          font-size: 16px;
        }
        .wide-chart-amonut {
          font-size: 22px;
          font-weight: 500 !important;
          line-height: 25.66px;
        }
      }
    }
  }
  .side-menu {
    .time-range__control {
      border-radius: unset !important;
      border-color: rgba(224, 224, 224, 1) !important;
    }
    .time-range__indicators {
      .time-range__indicator-separator {
        display: none;
      }
      .time-range__indicator {
        svg {
          color: rgba(51, 51, 51, 1) !important;
        }
      }
    }
    .time-range__menu {
      .time-range__menu-list {
        .time-range__option--is-disabled {
          color: #ccc !important;
        }
        .time-range__option {
          background-color: transparent;
          color: #3f4254;
        }
        .time-range__option:hover {
          background: #f2f2f2;
        }
        .time-range__option--is-selected {
          background: #f2f2f2;
        }
      }
    }
  }
}
.side-menu {
  .store__menu {
    .store__menu-list {
      .store__option--is-disabled {
        color: #ccc !important;
      }
      .store__option {
        background-color: transparent;
        color: #3f4254;
      }
      .store__option:hover {
        background: #f2f2f2;
      }
      .store__option--is-selected {
        background: #f2f2f2;
      }
      .menu-item {
        gap: 1rem !important;
      }
    }
  }
  .user__menu {
    .user__menu-list {
      .user__option--is-disabled {
        color: #ccc !important;
      }
      .user__option {
        background-color: transparent;
        color: #3f4254;
      }
      .user__option:hover {
        background: #f2f2f2;
      }
      .user__option--is-selected {
        background: #f2f2f2;
      }
      .menu-item {
        gap: 1rem !important;
      }
    }
  }
}

.analytics-top-bar {
  .top-bar {
    width: 1854px;
    height: 54px;
    position: absolute;
    left: -199px;
    display: flex;
    align-items: center;
    opacity: 0;
    .date-range-area {
      margin-left: 20px;

      background-color: rgba(255, 255, 255, 1) !important;
      color: rgba(33, 33, 33, 1) !important;
    }
  }
}
.report-popover {
  .popover-inner {
    width: 216px;
    height: 406px;
    .popover-body {
      padding: 0%;
      .report-popover-subnav {
        display: flex;
        flex-direction: column;
        margin-right: 12px;
        margin-top: 22px;
        gap: 22px;
        list-style: none;
        .menu-item {
          .menu-link {
            color: #333333 !important;
          }
        }
      }
    }
  }
}
.menu-item {
  .analytics-hovar {
    .bg-primary {
      color: #ffffff !important;
    }
  }
  .analytics-hover:hover {
    background-color: #e9e9e9 !important;
  }
}
.caregory-card {
  .main-content {
    .card {
      .card-body {
        padding: 0;
        .card-title {
          font-size: 14px;
        }
      }
    }
  }
}
.time-card {
  display: flex;
  flex-direction: row;
  .main-content {
    .card {
      .card-body {
        .card-title {
          font-size: 14px;
        }
      }
    }
    width: 84% !important;
    .time-chart {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: 100%;
      height: 190px;
      .selected-chart {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.3s ease-in-out;
      }
      .card {
        width: 24%; /* Adjust width to fit 4 cards in a row */
        margin-right: 10px;
        .card-title {
          font-size: 14px;
          margin-bottom: 43px;
        }
      }
      .card:last-child {
        margin-right: 0;
      }
      .apexcharts-svg {
        margin-left: -24px;
        .apexcharts-yaxis-texts-g {
          display: none;
        }
        .apexcharts-xaxis {
          display: none;
        }
      }
    }
    .invoice-chart {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: 100%;
      height: 150px;
      .selected-chart {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.3s ease-in-out;
      }
      .card {
        width: 40%; /* Adjust width to fit 4 cards in a row */
        margin-right: 10px;
        .card-title {
          font-size: 14px;
          margin-bottom: 43px;
        }
        .invoice-card {
          display: flex;
          flex-direction: column;
          align-items: start;
          div {
            font-weight: 500;
            font-size: 30px !important;
            align-self: center !important;
          }
        }
      }
      .card:last-child {
        margin-right: 0;
      }
      .apexcharts-svg {
        margin-left: -24px;
        .apexcharts-yaxis-texts-g {
          display: none;
        }
        .apexcharts-xaxis {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 842px) {
  .time-card {
    display: flex;
    flex-direction: column;
    .main-content {
      .card {
        .card-body {
          .card-title {
            font-size: 14px;
          }
        }
      }
      width: 100% !important;
      height: 100%;
      .time-chart {
        display: flex;
        flex-wrap: wrap !important;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        .selected-chart {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          transition: box-shadow 0.3s ease-in-out;
        }
        .card {
          width: 49%; /* Adjust width to fit 4 cards in a row */
          margin-right: 0px;
          height: 190px;
          .card-title {
            font-size: 14px;
            margin-bottom: 43px;
          }
        }
        .card:last-child {
          margin-right: 0;
        }
        .apexcharts-svg {
          margin-left: -24px;
          .apexcharts-yaxis-texts-g {
            display: none;
          }
          .apexcharts-xaxis {
            display: none;
          }
        }
      }
      .invoice-chart {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        .selected-chart {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          transition: box-shadow 0.3s ease-in-out;
        }
        .card {
          width: 40%; /* Adjust width to fit 4 cards in a row */
          margin-right: 10px;
          .card-title {
            font-size: 14px;
            margin-bottom: 43px;
          }
          .invoice-card {
            display: flex;
            flex-direction: column;
            align-items: start;
            div {
              font-weight: 500;
              font-size: 30px !important;
              align-self: center !important;
            }
          }
        }
        .card:last-child {
          margin-right: 0;
        }
        .apexcharts-svg {
          margin-left: -24px;
          .apexcharts-yaxis-texts-g {
            display: none;
          }
          .apexcharts-xaxis {
            display: none;
          }
        }
      }
    }
  }

  .tax-card {
    .side-menu {
      height: 867px !important;
    }
    .main-content {
      .small-card {
        .card-custom {
          .info-title {
            font-size: 14px;
          }
        }
      }
      .info-cards {
        margin-left: 0px;
        display: flex;
        flex-wrap: wrap !important;
        .net-sale-card {
          margin-left: 0px;
        }
        // .small-card{
        //   // width: 460px;
        // }
        // width: 460px !important;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .time-card {
    display: flex;
    flex-direction: column;
    .main-content {
      .card {
        .card-body {
          .card-title {
            font-size: 14px;
          }
        }
      }
      width: 100% !important;
      height: 100%;
      .time-chart {
        display: flex;
        flex-wrap: wrap !important;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        .selected-chart {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          transition: box-shadow 0.3s ease-in-out;
        }
        .card {
          width: 100%; /* Adjust width to fit 4 cards in a row */
          margin-right: 0px;
          height: 190px;
          .card-title {
            font-size: 14px;
            margin-bottom: 43px;
          }
        }
        .card:last-child {
          margin-right: 0px;
        }
        .apexcharts-svg {
          margin-left: 0px;
          .apexcharts-yaxis-texts-g {
            display: none;
          }
          .apexcharts-xaxis {
            display: none;
          }
        }
      }
      .invoice-chart {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        flex-direction: column !important;
        .selected-chart {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          transition: box-shadow 0.3s ease-in-out;
        }
        .card {
          width: 100%; /* Adjust width to fit 4 cards in a row */
          margin-right: 10px;
          height: 60px;
          .card-title {
            font-size: 14px;
            margin-bottom: 43px;
          }
          .invoice-card {
            display: flex;
            flex-direction: row !important;
            align-items: start;
            justify-content: space-between !important;
            div {
              font-weight: 500;
              font-size: 30px !important;
              margin-top: -3rem !important;
            }
          }
        }
        .card:last-child {
          margin-right: 0;
        }
        .apexcharts-svg {
          margin-left: -24px;
          .apexcharts-yaxis-texts-g {
            display: none;
          }
          .apexcharts-xaxis {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .invoice-btn-margin {
    margin-top: -1rem;
    padding-top: 8px !important;
  }
}
.custom-time-picker {
  background: white;
  border-radius: 8px;
}

.option {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 0.6em !important;
}

// .option input {
//   margin-right: 8px;
// }
.custom-time-picker.ltr .option input {
  margin-right: 8px;
}
.custom-time-picker.rtl .option input {
  margin-left: 8px;
}

.time-range {
  display: flex;

  gap: 4px;
}

.time-select {
  display: flex;
  flex-direction: column;
}
.css-tlfecz-indicatorContainer svg {
  fill: #4f4f4f !important ;
}

.time-select label {
  margin-bottom: 4px;
  font-weight: bold;
}
.css-1n7v3ny-option:hover {
  color: #2d9cdb;
}
.select {
  width: 120px;
}
.custom-options-card {
  .select {
    width: 100px;
    color: #4f4f4f !important;
    font-weight: 500 !important
    ;
    font-size: 14px !important;
  }
}
.custom-time-picker.ltr {
  .clock-icon {
    margin-left: 13px;
  }
}
.custom-time-picker.rtl {
  .clock-icon {
    margin-right: 13px;
  }
}
.custom-time-picker.ltr .selected-range input {
  input {
    flex: 1;
    border: none;
    background: none;
    cursor: pointer;
    margin-left: 5px;
  }
}
.custom-time-picker.rtl .selected-range input {
  flex: 1;
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 5px;
}
.custom-time-picker.ltr.arrow-icon {
  width: 12px;
  height: 6.4px;
  margin-right: 14px;
}
.custom-time-picker.rtl .arrow-icon {
  width: 12px;
  height: 6.4px;
  margin-left: 14px;
}

.custom-time-picker {
  .selected-range {
    .input-container {
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 36px;
      border: 1px solid #e0e0e0;
      input {
        border: none !important;
        outline: none !important;
      }
      input::placeholder {
        color: #4f4f4f;
        font-weight: 500;
        font-size: 14px;
      }
      .arrow-icon {
        width: 12px;
        height: 20px;
        margin-left: 1rem;
      }
      &.border-less {
        input {
          border: none !important;
          padding-left: 1.5rem;
        }
        .arrow-icon {
          margin-left: auto !important;
          height: 7px;
          width: 20px !important;
        }
      }
    }
    // input {
    //   flex: 1;
    //   border: none;
    //   background: none;
    //   cursor: pointer;
    //   margin-left: 5px;
    // }
  }
  .icon {
    margin: 0 5px;
  }
  // .clock-icon {
  //   margin-left: 13px;
  // }

  // .arrow-icon {
  //   width: 12px;
  //   height: 6.4px;
  //   margin-right: 14px;
  // }
  .picker-options {
    margin-top: 10px;
    &.custom-options-card {
      box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075) !important;
      padding: 1rem;
      // input {
      //   border: none;
      // }
      .time-select label {
        color: #828282 !important;
        font-size: 12px;
        font-weight: 400;
      }
      .option {
        border: none !important;
      }
      .css-1okebmr-indicatorSeparator {
        display: none !important;
      }
      .css-yk16xz-control {
        border: none !important;
        border-bottom: 1px solid #f2f2f2 !important;
        border-radius: 0;
      }
    }
  }
  .time-select {
    margin-top: 10px;
  }
}
.drp-calendar {
  .calendar-table {
    tbody {
      tr {
        .start-date {
          border-radius: 50% !important;
        }
        .end-date {
          border-radius: 50% !important;
        }
      }
    }
  }
}
.flex-column {
  .date-header {
    height: 54px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0 15px;
    span {
      margin-left: 15px !important;
    }
  }
}
.product-sidecard {
  .col-sm-12 {
    .card {
      .card-body {
        .filter-dialog {
          .col-title {
            font-size: 14px !important;
          }
        }
      }
    }
  }
}
.report-popover {
  .report-popover-subnav {
    .popover-title {
      font-size: 14px !important;
    }
  }
}

@media screen and (max-width: 843px) {
  .side-card-display {
    display: none !important;
  }
  .filter-icon-display {
    display: block !important;
  }
  .ml-none {
    margin-left: 0px !important;
  }
  .mr-none {
    margin-right: 0px !important;
  }
}

.side-card-display {
  display: block;
}

.filter-icon-display {
  display: none;
}

.side-card-dialog {
  box-shadow: none !important;
  border: none !important;
  width: 100% !important;
  margin: auto !important;
  box-shadow: none !important;
}

.custom-top-margin {
  margin-top: 3.25rem;
}
.custom-bottom-margin {
  margin-bottom: 3.25rem;
}
.invoice-main-wrap {
  padding: 1rem !important;
  font-size: 14px;
  font-weight: 400;

  .invoice-date-section {
    color: #828282 !important;
    padding-top: 0.5rem !important;
  }
  .invoice-total {
    border-bottom: 1px solid #e0e0e0 !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .bold-text {
    font-weight: 700 !important;
  }
}
.invoice-btn-margin {
  margin-top: -1rem;
}

@media screen and (max-width: 842px) {
  .flex-column {
    .date-header {
      height: 54px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
      span {
        margin-left: 15px !important;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .row {
    .col-sm-6 {
      .chart-card {
        height: auto !important;
        .card-body {
          .product-img-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;
            .elipse-icon {
              width: 150px;
              height: 150px;
            }
            .file-icon {
              width: 50px;
              height: 64px;
              position: absolute;
              bottom: 180px;
              right: 278px;
            }
          }
        }
        h3 {
          font-weight: 400;
          font-size: 14px;
          line-height: 15.77px;
        }
      }
    }
  }
}
.active-text {
  color: #2d9cdb;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}
.cancel-text {
  color: #828282;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}
.save-text {
  color: #2d9cdb80;
  font-weight: 700;
  font-size: 14px;
  cursor: auto;
}
.remove-logo-text {
  color: #eb5757;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}
.store-logo-sm {
  width: 70px;
  height: 70px;
  object-fit: contain !important;
}
.invoice-side-wrap {
  display: block;
  --mask: conic-gradient(from 130deg at top, #0000, #000 1deg 99deg, #0000 100deg) top/11.92px 51% repeat-x,
    conic-gradient(from -50deg at bottom, #0000, #000 1deg 99deg, #0000 100deg) bottom/11.92px 51% repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
}
.invoice-bordered-wrap {
  --mask: conic-gradient(from 130deg at top, #0000, #000 1deg 99deg, #0000 100deg) top/11.92px 51% repeat-x,
    conic-gradient(from -50deg at bottom, #0000, #000 1deg 99deg, #0000 100deg) bottom/11.92px 51% repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  border-width: medium;
}

@media screen and (max-width: 1054px) {
  .invoice-side-wrap {
    display: none;
  }
  .preview-invoice-card {
    display: block !important;
  }
}
.store-form-wrapper {
  margin-left: 10rem;
  max-width: 600px;
}
@media screen and (max-width: 540px) {
  .store-form-wrapper {
    margin-left: 0rem !important;
  }
  .date-header {
    &.store-invoice-header {
      padding-left: 0.5rem !important;
    }
  }
}

.date-header {
  &.store-invoice-header {
    padding-left: 10rem;
    span {
      margin-left: 20px !important;
    }
  }
}
.store-delete-card {
  background-color: #ffffff !important;
}
.preview-invoice-card {
  background-color: #ffffff !important;
  display: none;
}
@media screen and (min-width: 990px) {
  .store-sub-header {
    margin-right: -1.9rem !important ;
  }
}
.store-sub-header {
  margin-right: -1rem;
}
.store-sub-header.arabic-lang {
  margin-right: -7rem !important ;
  @media screen and (max-width: 500px) {
    margin-right: -1rem !important ;
  }
}

.zigzag-top,
.zigzag-bottom {
  width: 100%;
  height: 20px; /* Adjust height of zigzag */
  background: repeating-linear-gradient(-45deg, #ddd 0, #ddd 10px, transparent 10px, transparent 20px);
}

.zigzag-top {
  position: absolute;
  top: -10px; /* Adjust to overlap top */
  left: 0;
}

.zigzag-bottom {
  position: absolute;
  bottom: -10px; /* Adjust to overlap bottom */
  left: 0;
}
.tax-number-text {
  color: #828282;
  font-size: 11px;
  font-weight: 400;
}

.store-limit-text {
  color: #bdbdbd;
  font-size: 11px;
  text-align: right;
  margin-top: 0.5rem;
}
.store-small-text {
  color: #828282;
  font-size: 75%;
  font-weight: 400;
  opacity: 0;
}

.store-small-text-active {
  color: #2d9cdb;
  font-size: 75%;
  font-weight: 400;
  transform: translate3d(0, -100%, 0);
  opacity: 1;
  margin-bottom: -1.4rem;
}
.store-small-text-normal {
  color: #828282;
  font-size: 75%;
  font-weight: 400;
  transform: translate3d(0, -100%, 0);
  opacity: 1;
  margin-bottom: -1.4rem;
}

.blurry-text {
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.not-blurry-text {
  text-shadow: none !important;
  color: #333333 !important;
}

.small-text-user {
  font-size: 11px;
  color: #828282;
}
.large-text-user {
  color: #333333;
  font-size: 14px;
  font-weight: 400;
}

.product-form-heading {
  font-size: 16px;
  font-weight: 700;
  color: #333333;
}

.product-filter-wrapper {
  position: absolute;
  top: -18px;
  left: 0;
  background: #ffffff;
  z-index: 100;
  box-shadow: 0px 4px 4px 0px #00000040;
  max-width: 350px;
  padding: 1rem;
  @media screen and (max-width: 558px) {
    left: -86px;
  }
}
.product-filter-wrapper-arabic {
  position: absolute;
  top: -18px;
  right: 0;
  background: #ffffff;
  z-index: 100;
  box-shadow: 0px 4px 4px 0px #00000040;
  max-width: 350px;
  padding: 1rem;
  @media screen and (max-width: 558px) {
    right: -86px;
  }
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.aside-dynamic-padding-arabic {
  padding-right: 70px;
  @media screen and (max-width: 529px) {
    padding-right: 0px;
  }
}
.aside-dynamic-padding-arabic-content {
  padding-right: 265px;
  @media screen and (max-width: 529px) {
    padding-right: 0px;
  }
}

.normal-font {
  font-size: 14px;
}
.company-edit-text {
  color: #2d9cdb;
  width: auto;
  height: auto;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.07px;
  cursor: pointer;
}