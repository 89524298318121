.side-menu {
    width: 270px !important;
    height: 880px;
  }
  
  /* .main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  } */
  
  .card-content {
    flex: 0 0 24%;
    margin-right: 1%;
    margin-bottom: 20px;
  }
  
  .card-content:last-child {
    margin-right: 0;
  }
  
  .card-bodys {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  
  .chart-container {
    flex-grow: 1;
  }
.active-background{
  background-color: #e9e9e9 ;
}
.selectedChart{
  box-shadow: 10px 10px 5px #dcdde2;
}
  
  