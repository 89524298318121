//
// Aside
//

.header-subscription-text {
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}

.aside {
  width: get($aside-config, base, width);

  // Aside Menu
  .aside-menu {
    @include perfect-scrollbar-ver-offset(4px);
  }
}

// Desktop Mode
@include media-breakpoint-up(lg) {
  .aside {
    // Fixed Aside Mode
    .aside-fixed & {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: get($aside-config, base, zindex);
    }
    .aside-absolute & {
      position: fixed;
      right: 0;
      z-index: get($aside-config, base, zindex);
    }

    // Static Aside Mode
    .aside-static & {
      position: relative;
      z-index: 1;
    }

    // Fixed Header & Static Aside Modes
    .header-fixed.aside-static & {
      padding-top: get($header-config, desktop, default, height);
    }

    // Minimized Aside Mode
    .aside-minimize & {
      width: get($aside-config, base, minimized-width);
    }

    // Scrollbar Width
    @include perfect-scrollbar-ver-size(get($aside-config, base, scrollbar-width));
  }

  // Fixed Aside & Minimize Aside Hover Modes
  .aside-fixed.aside-minimize-hover {
    .aside {
      width: get($aside-config, base, width);
    }

    .wrapper {
      padding-left: get($aside-config, base, minimized-width);
    }
  }

  // Fixed Aside, Fixed Header & Minimize Aside Hover Modes
  .aside-fixed.header-fixed.aside-minimize-hover {
    .header {
      left: get($aside-config, base, minimized-width);
    }
  }
}

// Build Aside Menu
@include menu-ver-build-layout($aside-menu-config);

// Aside Offcanvas Panel For Mobile Mode
@include offcanvas-build(aside, tablet-and-mobile, get($aside-config, offcanvas-mobile));

.aside-menu-list {
  width: 219px;
  height: 454px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
  p {
    margin-left: 20px;
    a {
      color: #4f4f4f;
    }
  }
}

.users-pagination-icons {
  display: flex;
  gap: 8px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    span {
      color: #bdbdbd;
    }
  }
}

.spaced-rows {
  margin-bottom: 10px;
  padding-top: 40px;
}

.custom-checkbox {
  border: "2px solid red";
}

.add-company-user {
  display: flex;
  gap: 20px;

  img {
    cursor: pointer;
  }

  .add-company-user-add-button {
    color: #2d9cdb;
    width: 70px;
    height: 25px;
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    cursor: pointer;
  }
  .add-company-back-to-subscription {
    color: #2d9cdb;
    height: 25px;
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    cursor: pointer;
  }
}

.users-managment {
  font-weight: 500;
  font-size: 20px;
  color: #333333;
}

.manage-access-rights {
  font-weight: 400;
  font-size: 15px;
  color: #828282;
}

@media screen and (max-width: 413px) {
  .manage-access-rights {
    font-size: 13px !important;
    white-space: nowrap;
  }
}
.try-for-30-days {
  font-weight: 400;
  font-size: 14px;
  color: #828282;
}

.trial-expiration-date {
  width: 240px;
  height: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  // color: #eb5757;
}

.try-for-free {
  font-weight: 700;
  font-size: 14px;
  color: #2d9cdb;
  cursor: pointer;
}

.payment-card {
  font-weight: 500;
  font-size: 18px;
  color: #333333;
}

.add-payment-card {
  font-weight: 700;
  font-size: 14px;
  color: #2d9cdb;
  cursor: pointer;
}

.payment-card-year {
  margin-left: 3rem;
  margin-right: 3rem;
}

@media screen and (max-width: 428px) {
  .payment-card-year {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
.credit-card-added {
  font-weight: 500;
  font-size: 14px;
  color: #828282;
}

.plan-price-users-next-bill-date {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .plan-price {
    height: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
  }

  .plan-price-users {
    height: 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #828282;
  }

  .next-bill-date {
    width: 220px;
    height: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
  }
}

.unsubscribe-btn {
  height: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #bdbdbd;
  cursor: pointer;
}

.invoice-heading {
  width: 80px;
  height: 25px;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
  text-align: start;
  margin-left: 6px;
  margin-right: 8px;
}

.rtl {
  direction: rtl;
  text-align: right;
}

.ltr {
  direction: ltr;
  text-align: left;
}

.rtl.card {
  direction: rtl;
  text-align: right;
}

.ltr.card {
  direction: ltr;
  text-align: left;
}

.invoice-container {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.title {
  font-size: 16px;
  font-weight: 700;
}

.invoice {
  width: 794px;
  height: 1123px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
  padding: 20px;

  .invoice-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;

    .invoice-logos {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
    }

    .invoice-title {
      width: 30%;
      margin-top: 15px;

      span {
        font-weight: 500;
        font-size: 25px;
      }
    }
  }

  .invoice-info {
    display: flex;
    padding-top: 40px;
    justify-content: space-between;
    width: 100%;

    .company-info,
    .invoice-details {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-bottom: 1px solid #e0e0e0;

      span {
        font-size: 16px;
      }
    }

    .invoice-details {
      padding-bottom: 20px;
      width: 30%;

      .status-paid {
        font-weight: 600;
        color: #27ae60;
        text-transform: uppercase;
      }
    }
  }

  .billing-info {
    display: flex;
    padding-top: 40px;
    justify-content: space-between;
    width: 100%;

    .billed-to,
    .subscription-info {
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-bottom: 1px solid #e0e0e0;
      font-size: 16px;

      .title {
        font-weight: 700;
        font-size: 16px;
      }
    }

    .billed-to {
      padding-bottom: 20px;
    }
  }

  .invoice-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    margin-top: 40px;

    th,
    td {
      padding: 8px;
      border-bottom: 1px solid #e0e0e0;
    }

    th {
      text-align: left;

      &.unit-price,
      &.amount {
        text-align: end;
      }
    }

    td {
      font-size: 16px;
      &.unit-price,
      &.amount {
        text-align: end;
      }
    }
  }

  .total-info {
    width: 100%;
    display: flex;
    justify-content: end;

    .amounts {
      font-size: 16px;
      width: 18%;
      text-align: right;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .title {
        font-weight: 700;
        font-size: 16px;
      }
      .amount-due {
        padding-top: 4px;
        padding-bottom: 4px;
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
      }
    }

    .totals {
      font-size: 16px;
      width: 18%;
      text-align: left;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .title {
        font-weight: 700;
        font-size: 16px;
      }
      .amount-due {
        padding-top: 4px;
        padding-bottom: 4px;
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
      }
    }
  }

  .credits-info {
    font-size: 16px;
    width: 100%;

    .credits-title {
      border-bottom: 1px solid #e0e0e0;
      width: 30%;

      padding-bottom: 15px;
      font-weight: 700;
      font-size: 16px;
    }
  }
}

.invoice-container-arabic {
  display: flex;
  justify-content: center;
  direction: rtl;
}

.available-credit-note {
  font-weight: 500;
  font-size: 14px;
  color: #4f4f4f;
}

.total-info-arabic {
  width: 100%;
  display: flex;
  justify-content: end;

  .amounts {
    width: 16%;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      font-weight: 700;
      font-size: 16px;
    }
    .amount-due {
      padding-top: 4px;
      padding-bottom: 4px;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
    }
  }

  .totals {
    width: 16%;
    text-align: right;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      font-weight: 700;
      font-size: 16px;
    }
    .amount-due {
      padding-top: 4px;
      padding-bottom: 4px;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
    }
  }
}

.sidebar-subscribe-button {
  width: 234px;
  height: 48px;
  color: #9b51e0;
  border-radius: 25px;
  background-color: white;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  font-size: 16px;
}

.invoice-qr-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.add-text-btn {
  height: 25px;
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  color: #2d9cdb;
  cursor: pointer;
}

.you-have-no-taxes {
  height: 28px;
  font-weight: 400;
  font-size: 25px;
  line-height: 27.9px;
  color: #828282;
}

.add-edit-tax {
  height: 27px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}
.invoice-preview-text {
  height: 27px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
}

.cursor-pointer {
  cursor: pointer;
}

@media screen and (max-width: 515px) {
  .custom-margin-top {
    margin-top: -0.5rem !important;
  }

  .subscription-margin {
    margin-top: 0px;
  }
}

@media screen and (max-width: 500px) {
  .w-full-mob {
    width: 100% !important;
  }
  .d-medium-none {
    display: block !important;
  }
  .d-small-none {
    display: none !important;
  }
}
.custom-margin-top {
  margin-top: -3.8rem;
}

.subscription-margin {
  margin-top: -1rem;
}

.d-medium-none {
  display: none;
}
.d-small-none {
  display: block;
}

@media (max-width: 991.98px) {
  .aside.arabic-layout {
    z-index: 1001;
    position: fixed;
    top: 0;
    bottom: 0;
    transition: right 0.3s ease, left 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    right: -295px;
    width: 275px;
    left: auto;
  }
  .aside.arabic-layout.aside-on {
    transition: right 0.3s ease, left 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    right: 0;
  }
}
