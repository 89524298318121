/* 
.sidebar {
    position: fixed;
    right: -300px;
    top: 0;
    width: 300px;
    height: 100%;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease;
    padding: 20px;
    z-index: 1000;
  }
  
  .sidebar.open {
    right: 0;
  }
  

  
  .sidebar label {
    display: block;
    margin-bottom: 10px;
  }
  
  .sidebar button {
    margin-top: 20px;
  } */
  .sidebar h5 {
    margin-bottom: 20px;
    text-align: center;
    display: none;
    background: #2d9cdb;
    border-radius: 0;
    padding: 1rem;
    width: 100%;
  }
  .sidebar button {
    margin-top: 20px;
    display: none;
  }
  .sidebar {
    position: absolute;
    bottom: calc(100% + -148px);
    right: -8px;
    width: 300px;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease, visibility 0.3s ease;
    opacity: 0; 
    visibility: hidden;
    padding: 20px;
    z-index: 1000;
    width: 239px;
  }
  
  .card-body {
    position: relative;
  }
  
  .card-body .sidebar.open {
    opacity: 1; 
    visibility: visible; 
  }
  
  /* .apexcharts-xaxis-label{
    display: none !important;
  }
  .apexcharts-yaxis-texts-g{
    display: none !important;
  } */
  .apexcharts-grid-borders{
    display: none !important;
  }