@import "./variables";

/* FORMS */
.field-info {
  position: relative;
  i {
    font-size: 0.9rem;
    &:hover {
      cursor: pointer;
      ~ span {
        display: block;
      }
    }
  }
  span {
    font-size: 0.9rem;
    display: none;
    z-index: 1;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: $themePrimaryColor;
    padding: 0.5rem;
    border-radius: 4px;
    color: $light;
    &:after {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-top-color: $themePrimaryColor;
      border-width: 10px;
      margin-left: -10px;
    }
  }
}

.form-group {
  input {
    width: 100%;
    height: 34px;
  }
  position: relative;
  margin-bottom: 2.5rem !important;
  &.input-select {
    padding: 0;
    border: 0;
    .react-select {
      width: 100%;
      [class*="-control"] {
        border-radius: 0;
        border: 0;
        border-bottom: 1px solid #e0e0e0;
        height: 35px;
        &:focus-within {
          border: 0 !important;
          border-bottom: 1px solid #e0e0e0 !important;
          outline: none !important;
        }
      }
      [class*="-placeholder"] {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      [class*="-ValueContainer"] {
        padding: 0;
      }
      [class*="-indicatorContainer"] {
        padding-right: 0;
      }
      [class*="-indicatorSeparator"] {
        display: none;
      }
    }
    svg {
      width: 15px;
      color: #6c8294;
    }
  }
  &.with-icon {
    display: flex;
    span {
      width: 50px;
      height: 30px;
      display: flex;
      align-items: center;
      i {
        font-size: 1.5rem;
        color: #828282;
      }
    }
    field {
      width: 100%;
    }
  }
  label {
    pointer-events: none;
    position: absolute;
    top: 0.5rem;
    transition: all 200ms;
    opacity: 0;
    font-size: 1rem;
    margin: 0;
    padding: 0;
    color: #828282 !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  &.thick-border {
    .form-control {
      border-bottom: 2px solid #e0e0e0;
    }
  }
  &.red-border {
    .form-control.is-invalid {
      border-bottom: 2px solid #eb5757 !important;
    }
  }
  .form-control {
    border: 0;
    border-bottom: 1px solid #e0e0e0;
    border-radius: 0;
    padding: 0.25rem 0;
    height: calc(1.5em + 0.5rem + 2px);
    font-weight: 500;
    &.is-invalid,
    &.is-valid {
      border-color: #e0e0e0 !important;
    }
    &.thick-border {
      border-bottom: 2px solid #e0e0e0;
    }
    &.red-border {
      border-bottom: 2px solid #eb5757 !important;
    }
  }
  &::placeholder {
    color: #828282;
  }
  // .form-control:focus + label,
  // .form-control:valid + label,
  .form-control:not(:placeholder-shown) + label {
    font-size: 75%;
    transform: translate3d(0, -100%, 0);
    opacity: 1;
  }
  .form-control:focus {
    border-color: rgba($themePrimaryColor, 1) !important;
    + label,
    + i {
      color: $themePrimaryColor !important;
    }
  }
  .feedback {
    display: none !important;
  }
  .success-feedback,
  .valid-feedback {
    display: none;
  }

  .feedback,
  .invalid-feedback
  // .success-feedback,
  // .valid-feedback
 {
    display: block;
    position: absolute;
    bottom: -1.25rem;
  }
  .invalid-feedback.arabic-lang {
    // right: 10px;
    right: 10px;
    display: block;
    position: absolute;
    bottom: -1.25rem;
  }

  &.datepicker {
    .react-datepicker-wrapper {
      max-width: 150px;
      position: relative;
      &:after {
        content: "";
        background: url(../img/icon-calendar.svg) center right no-repeat;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  &.form-select {
    select {
      border: 0 !important;
      padding: 0.25rem 0 !important;
      border-bottom: 1px solid #e0e0e0 !important;
    }
  }
  &.view-select {
    select {
      appearance: none;
    }
  }
}
/* CHECKBOX / RADIO / SWITCH */
.custom-control {
  margin: 0.5rem 0;
  .custom-control-label {
    font-size: 1.1rem;
    line-height: 1.75;
    font-weight: 400 !important;
  }
  &.custom-checkbox {
    .custom-control-label {
      color: $textPrimaryColor;
      line-height: 1.4;
      font-weight: 500;
      &::before {
        background: none;
        border-radius: $borderRadiusBase;
        border-color: rgba($themePrimaryColor, 0.8);
        top: 0.15rem;
        left: -1.5rem;
        width: 1.1rem;
        height: 1.1rem;
      }
      &:after {
        transition: all $transition;
        transform: scale(0);
      }
    }
    .custom-control-input {
      z-index: 0;
      &:checked {
        ~ .custom-control-label {
          &::before {
            // background: $themePrimaryColor;
            // border: 1px solid $themePrimaryColor;
            // border-color: rgba($themePrimaryColor, 1);
          }
          &:after {
            background: url(../img/icon-checkbox-check.svg) center center no-repeat;
            background-size: 12px;
            top: 0.2rem;
            left: -1.45rem;
            transform: scale(1);
          }
        }
      }
    }
  }
  &.custom-radio {
    line-height: 1.7;
    .custom-control-label {
      padding-left: 0.5rem !important;
      &:before {
        border: 1px solid $themePrimaryColor !important;
        width: 1.25rem;
        height: 1.25rem;
      }
    }
    .custom-control-input {
      &:checked {
        ~ .custom-control-label {
          &::before {
            border-color: $themePrimaryColor;
            background-color: $themePrimaryColor;
          }
          &:after {
            background: none;
          }
        }
      }
    }
  }
  &.custom-switch {
    .custom-control-label {
      color: #a3a8ad;
      &::before {
        background: #c4c4c4;
        border-color: rgba(23, 63, 95, 0.5);
      }
      &:after {
        background: $light;
      }
    }
    .custom-control-input {
      &:checked {
        ~ .custom-control-label {
          color: rgba($themePrimaryColor, 1);
          &::before {
            background: #18a0fb;
            border-color: rgba($themePrimaryColor, 1) !important;
          }
        }
      }
    }
    &.default-switch {
      display: flex;
      align-items: center;
      .custom-control-label {
        padding-left: 1rem;
        &:before {
          height: 0.4rem;
          width: 2.5rem !important;
          top: 0.8rem;
          // left: -5rem !important;
          background: #c4c4c4 !important;
          border: 0;
        }
        &:after {
          width: 15px !important;
          height: 15px !important;
          border-radius: 40px !important;
          background: grey !important;
          font-size: 1rem;
          color: $light;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      input:checked ~ .custom-control-label::after {
        transform: translateX(1rem) !important;
        background: #1d7fc1 !important;
      }
      input:checked ~ .custom-control-label::before {
        background: #c5e4f5 !important;
      }
    }
    &.switch-by-value {
      .custom-control-label {
        &::before {
          height: 0.5rem;
          width: 5rem !important;
          left: -5rem !important;
          background: #96d5ff !important;
          border: 0;
        }
        &:after {
          top: calc(-0.6rem + 2px) !important;
          left: calc(-5rem + 2px) !important;
          width: 35px !important;
          height: 25px !important;
          border-radius: 40px !important;
          background: $themePrimaryColor !important;
          font-size: 1rem;
          color: $light;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      input:checked ~ .custom-control-label::after {
        transform: translateX(1.9rem) !important;
      }
      &.switch-button {
        .custom-control-label {
          &:after {
            border-radius: $borderRadiusBase !important;
            left: -5rem !important;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }
          &::before {
            border: 1px solid #e0e0e0 !important;
            height: 2rem;
            width: 4.8rem !important;
            border-radius: $borderRadiusBase;
            top: -0.5rem;
            background: #ffffff !important;
          }
        }
        input:checked ~ .custom-control-label::after {
          transform: translateX(2.1rem) !important;
        }
      }
      &.switch-sigma-perc {
        .custom-control-label {
          &:after {
            content: "Σ";
          }
        }
        input:checked ~ .custom-control-label::after {
          content: "%";
          font-size: 1.2rem;
        }
      }
      &.switch-sar-perc {
        .custom-control-label {
          &:after {
            content: "SAR";
          }
        }
        input:checked ~ .custom-control-label::after {
          content: "%";
          font-size: 1.2rem;
        }
      }
      &.switch-sar-numb {
        .custom-control-label {
          &:after {
            content: "%";
          }
        }
        input:checked ~ .custom-control-label::after {
          content: attr(currency-name);
          background: $themePrimaryColor url(../img/icon-number.svg) center center no-repeat !important;
          // background-repeat: no-repeat !important;
          // background-position: center center !important;
          // filter: invert(53%) sepia(12%) saturate(22%) hue-rotate(9deg)
          //   brightness(94%) contrast(88%);
          // width: 5px;
          // height: 5px;
        }
      }
    }
  }
}
.emailMessageField.arabic-layout {
  label {
    right: 0.8rem !important;
  }
}
