@import "./variables";

$DrawerSize: 360px;
.modal {
  &.ar {
    .modal-dialog {
      position: fixed;
      transform: translate(400px, 0) !important;
      left: 0;
      bottom: 0;
      margin: 0;
      max-width: $DrawerSize !important;
      // [class*="close-"] {
      //   position: relative;
      //   top: -0.5rem;
      //   display: inline-block;
      //   margin-bottom: 1rem;
      // }
    }
    .modal-content {
      min-width: $DrawerSize;
      max-width: $DrawerSize;
      height: 93svh;
      @supports not (height: 93svh) {
        height: 93vh; // Fallback for browsers that don’t support `svh`
      }
      border-radius: 4px;
      overflow-y: auto;
      overflow-x: hidden;

      .close-dialog {
        display: none;
      }
      .close-drawer {
        img {
          filter: invert(51%) sepia(0%) saturate(350%) hue-rotate(165deg) brightness(98%) contrast(90%);
        }
      }
    }
  }
  &.invoice-preview-modal {
    .modal-body {
      padding: 3rem;
    }
    .modal-header {
      background-color: transparent !important;
    }
  }
  &.modal-drawer {
    .modal-dialog {
      position: fixed;
      transform: translate(400px, 0) !important;
      right: 0;
      bottom: 0;
      margin: 0;
      max-width: $DrawerSize !important;
      // [class*="close-"] {
      //   position: relative;
      //   top: -0.5rem;
      //   display: inline-block;
      //   margin-bottom: 1rem;
      // }
    }
    &.modal-dialog-arabic {
      .modal-dialog {
        position: fixed;
        transform: translate(-400px, 0) !important;
        left: 0;
        bottom: 0;
        margin: 0;
        max-width: $DrawerSize !important;
      }
    }

    &.full-height-dialog {
      .modal-content {
        height: 100svh;
        @supports not (height: 100svh) {
          height: 100vh; // Fallback for browsers that don’t support `svh`
        }
      }
    }
    &.small-width {
      .modal-content {
        min-width: 270px;
        max-width: 270px;
      }
    }
    .modal-content {
      min-width: $DrawerSize;
      max-width: $DrawerSize;

      height: 93svh;
      @supports not (height: 93svh) {
        height: 93vh; // Fallback for browsers that don’t support `svh`
      }

      border-radius: 4px;
      overflow-y: auto;
      overflow-x: hidden;
      .close-dialog {
        display: none;
      }
      .close-drawer {
        img {
          filter: invert(51%) sepia(0%) saturate(350%) hue-rotate(165deg) brightness(98%) contrast(90%);
        }
      }
    }
  }

  .modal-header {
    text-align: center;
    display: inline-block;
    background: $themePrimaryColor;
    position: sticky;
    top: 0;
    z-index: 1000;
    border-radius: 0;
    padding: 1rem;
    .modal-title {
      color: $light !important;
      .custom-text-invoice {
        color: #3f4254 !important;
      }
    }

    .btn-close {
      padding: 0;
      position: absolute;
      left: 1rem;
    }
  }
  .btn-bar {
    .btn {
      background: $themePrimaryColor;
      text-transform: capitalize;
      padding: 0.5rem 1.5rem;
      border-radius: 4px;
    }
  }
  .modal-footer {
    text-align: center;
    display: flex;
    justify-content: center;
    border: 0;
    // padding: 0;
  }
  &.show {
    &.modal-drawer {
      .modal-dialog {
        transform: translate(0, 0) !important;
      }
    }
  }
}

.user-drawer {
  padding: 0 !important;
  margin-top: 67px;
  .user-header {
    margin-left: 0px;
    padding: 0.5rem 1rem;
    align-items: center;
    height: 50px;
    background-color: #2d9cdb;
    display: flex;
    h4 {
      padding-top: 0.4rem;
      padding-left: 6rem;
      color: white;
    }
    button {
      box-shadow: none;
    }
  }
  .user-option {
    margin-top: 10px;
    a {
      display: block;
      width: 100%;
      padding: 1rem 2.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        margin-right: 1rem;
      }
      // &:after {
      //   content: "";
      //   background: url(../img/icon-user-menu-arrow-right.png) center right
      //     no-repeat;
      //   width: 10px;
      //   height: 10px;
      //   float: right;
      // }
    }
    span {
      color: black;
      font-weight: 500;
    }
  }
}

.my-profile {
  .user-avatar {
    max-width: 70px;
    height: 70px;
    margin: 0 auto;
    position: relative;
    .img-wrap {
      max-width: 70px;
      height: 70px;
      display: flex;
      justify-content: center;
      border-radius: 100px;
      overflow: hidden;
      img {
        width: auto;
        height: 70px;
      }
    }
    .img-wrap-header {
      max-width: 60px;
      height: 55px;
      display: flex;
      justify-content: center;
      border-radius: 100px;
      overflow: hidden;
    }
    .btn-edit {
      border-radius: 100%;
      border: 1px solid $textPrimaryColor;
      background: $light;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 25px;
      height: 25px;
      // max-width: 25px;
      // min-width: 25px;
      // min-height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 20px;
        font-size: 0.9rem;
        padding: 0;
        color: $textPrimaryColor;
        filter: invert(53%) sepia(31%) saturate(270%) hue-rotate(165deg) brightness(87%) contrast(90%);
      }
      input {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
  ul {
    padding: 1.5rem 1rem;
    border: 1px solid $borderColor;
    border-radius: 5px;
    margin: 2rem 0;
    li {
      display: flex;
      align-items: center;
      padding: 0.5rem 0;
      &:last-child {
        padding-bottom: 0;
      }
      &:first-child {
        padding-top: 0;
      }
    }
  }
  .user-detail {
    padding: 1.5rem 1rem;
    border: 1px solid $borderColor;
    border-radius: 5px;
    li {
      .icon-wrap {
        min-width: 40px;
        // text-align: center;
        // display: inline-block;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-right: 1rem;
        }
      }
      label {
        color: $textPrimaryColor;
        font-weight: 500;
        font-size: 1.1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        span {
          //   display: none;
          margin-right: 0.5rem;
          float: left;
          min-width: 60px;
          color: $textLightColor;
          &:after {
            content: ":";
          }
        }
        a {
          color: $themePrimaryColor;
          font-weight: 400;
        }
      }
    }
  }
  form {
    .input-group {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      label {
        font-weight: 600;
        margin: 0 0.5rem 0 0;
        min-width: 150px;
      }
      .form-control {
        border: 1px solid $borderColor;
        border-radius: 4px;
      }
    }
  }
  .pin-pad {
    margin-top: 120px !important;
    p {
      margin-top: 50px;
    }
  }
}
.form-control {
  &.is-invalid {
    background-image: none !important;
  }
}

.roles-drawer {
  .roles-list {
    list-style: none;
    padding: 0;
    li {
      color: $themePrimaryColor;
      display: flex;
      justify-content: space-between;
      padding: 0.5rem 0;
      .role-name {
        position: relative;
        padding-left: 1.5rem;
        a {
          position: absolute;
          left: 0;
          margin-right: 0.5rem;
        }
      }
      .role-actions {
        a {
          margin-right: 0.75rem;
        }
      }
    }
  }
  .role-edit {
    th,
    td {
      border: 0 !important;
      color: #6c8294;
    }
  }
}

.select-dur {
  .form-group {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    margin-bottom: 2rem !important;
  }
  .date-range {
    margin-top: 3rem;
  }
}

.invoice-drawer {
  .modal-footer {
    flex-direction: column;
  }
  .card {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
    border-radius: 0;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25) !important;
    margin-bottom: 0.5rem;
    &.shadow-less {
      box-shadow: none !important;
    }
    .invoice-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      padding: 0 1.5rem 1rem;
      border-bottom: 1px solid #e0e0e0;
      margin-left: -1.5em;
      margin-right: -1.5em;
    }
    small {
      color: #828282;
    }
    p {
      line-height: 1;
      font-weight: 600;
      font-size: 1.1rem;
    }
  }
  .invoice-total {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0.75rem;
        font-size: 1.15rem;
        span {
          font-weight: 500;
        }
        strong {
          font-weight: 600;
        }
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}
.invoice-drawer-info {
  .modal-footer {
    flex-direction: column;
  }
  .modal-body {
    padding-top: 0;
  }
  .card {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
    border-radius: 0;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25) !important;
    margin-bottom: 0.5rem;
    .invoice-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      padding: 0 1.5rem 1rem;
      border-bottom: 1px solid #e0e0e0;
      margin-left: -1.5em;
      margin-right: -1.5em;
    }
    small {
      color: #828282;
    }
    p {
      line-height: 1;
      font-weight: 600;
      font-size: 1.1rem;
    }
  }
  .invoice-total {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0.75rem;
        font-size: 1.15rem;
        span {
          font-weight: 500;
        }
        strong {
          font-weight: 600;
        }
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}
$clipPathSize: 170px;
.clip-preview {
  max-width: $clipPathSize;
  width: $clipPathSize;
  height: $clipPathSize;
  margin: 2rem auto 0;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
  p {
    height: $clipPathSize;
    display: flex;
    justify-content: center;
    align-items: center;
    // transform: translate(-50%, -50%);
    color: white;
    margin: 0;
  }
  &.with-img {
    p {
      height: 40px;
      width: $clipPathSize;
      display: flex;
      justify-content: flex;
      align-items: center;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      color: white;
      margin: 0;
      background: rgba(0, 0, 0, 0.5);
      font-size: 1.5rem;
      font-weight: 300;
      border-radius: 5px 5px 0 0;
    }
  }
}

.color-selection {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  li {
    width: 2em;
    height: 2em;
    &.selected {
      &:after {
        content: "";
        background: url(../img/check-color-select.svg) center center no-repeat;
        width: 2em;
        height: 2em;
        display: block;
      }
    }
  }
}
