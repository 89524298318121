// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  tr {
    cursor: pointer !important;
  }
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}
// Fatoraty styling

.f-text-primary {
  color: #333333 !important;
}

.f-full-width-center {
  width: 100% !important;
  justify-content: center !important;
  text-align: center !important;
}

.input-icon {
  > input {
    padding-left: 35px !important;
  }

  > select {
    padding-left: 35px !important;
  }
}

.f-mobile-icon {
  position: absolute;
  margin-top: 22px;
  margin-left: 10px;
}

.input-with-drop-down {
  .react-tel-input {
    // margin-left: 12px !important;
    .form-control {
      background: none !important;
      background-color: none !important;
      border: none !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
    .flag-dropdown {
      background: none !important;
      background-color: none !important;
      border: none !important;
    }
  }
}

.input-with-drop-down-v2 {
  .react-tel-input {
    margin-top: -8px;
    .form-control {
      background: none !important;
      background-color: none !important;
      border: none !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
    .flag-dropdown {
      background: none !important;
      background-color: none !important;
      border: none !important;
    }
  }
}

.account-type {
  .card {
    background-color: #f3f6f9;
    height: 160px;
    justify-content: center;

    svg {
      margin-left: auto;
      margin-right: auto;
      path {
        fill: #2d9cdb;
      }
    }
    p {
      margin-top: 1rem;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0px;
    }
  }

  :hover {
    cursor: pointer;
    svg {
      path {
        fill: #173f5f;
      }
    }
  }
}

.active-account-type {
  .card {
    background-color: #2d9cdb;
    height: 160px;
    justify-content: center;

    svg {
      margin-left: auto;
      margin-right: auto;
      path {
        fill: white;
      }
    }
    p {
      color: white;
      margin-top: 1rem;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0px;
    }
  }

  :hover {
    cursor: pointer;
    svg {
      path {
        fill: white;
      }
    }
  }
}

.brand-logo-color {
  color: white !important;
  :hover {
    color: white !important;
  }
}

.menu-toggleAction {
  :hover {
    color: white !important;
  }
}
.f-sideMenu-toggle {
  svg {
    path {
      fill: white;
    }
  }
  :hover {
    cursor: pointer;
    span {
      svg {
        g {
          path {
            fill: white;
          }
        }
      }
    }
  }
}
.flag-dropdown {
  height: 2.7rem;
}
.demoFlagInputHeight {
  height: 3rem;
}

.f-button {
  background-color: $primary !important;
  border-color: $primary !important;
  // width : 100%;
  &:hover {
    background-color: #173f5f !important;
    border-color: #173f5f !important;
  }
}

.react-tel-input .form-control {
  width: 100% !important;
}

.f-switch {
  label {
    margin-left: 0px;
    span {
      white-space: pre;
      font-weight: bold;
    }
  }
}
.f-module {
  margin: 0 0 1em 0;
  line-height: 160%;
  margin: 0;
  text-align: justify;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  height: 34px;
}
.f-overflow {
  --max-lines: 3;
  position: relative;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  padding-right: 1rem; /* space for ellipsis */
}
.f-overflow::before {
  position: absolute;
  content: "...";
  /*   inset-block-end: 0;
  inset-inline-end: 0; */
  bottom: 0;
  right: 0;
}
.f-overflow::after {
  content: "";
  position: absolute;
  /*   inset-inline-end: 0; */
  right: 0;
  width: 1rem;
  height: 1rem;
  background: white;
}

.f-confirmation-modal {
  .modal-content {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
.f-selector {
  border: 1px solid #e4e6ef !important;
  box-shadow: 0 !important;
}
.css-1pahdxg-control {
  border: 1px solid #3f4254 !important;
  box-shadow: 0 0 0 0 #3f4254 !important;
}
.f-font {
  font-size: 15px;
  font-weight: 600;
}
.f-penIcon {
  color: #20639b;
  margin: 43px 9px 0px 0px;
  cursor: pointer;
}
.f-payment {
  background-color: #379f95 !important;
  border-color: #379f95 !important;
  :hover {
    background-color: #379f95 !important;
  }
}
.f-refund {
  background-color: #ed553b !important;
  border-color: #ed553b !important;
}
.f-select {
  padding: 0px !important;
  padding-right: 0px !important;
  height: 40px !important;
}
.f-password {
  background-image: none !important;
}
.css-2613qy-menu {
  background: #e4e6ef !important;
}

.f-customers {
  background-color: #3d95dc !important;
  border-color: #3d95dc !important;
  // width : 100%;
  &:hover {
    background-color: #27618f !important;
    border-color: #27618f !important;
  }
}
.f-invoices {
  background-color: #00bb7e !important;
  border-color: #00bb7e !important;
  // width : 100%;
  &:hover {
    background-color: #006e4a !important;
    border-color: #006e4a !important;
  }
}
.selection-cell-header {
  width: 20px !important;
}
.text-width {
  width: 160px !important;
}
.text-price {
  width: 100px !important;
}

.f-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 300px;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    padding: 8px 12px;
  }
  li:hover {
    background-color: rgba(0, 0, 0, 0.14);
    cursor: pointer;
  }
}
.btn.btn-primary.dropdown-toggle:after {
  color: black;
}

#f-test {
  background-color: white !important;
  border: none !important;
}

.react-bootstrap-table .dropdown {
  position: inherit !important;
}
.dropdown-menu.show {
  // position: relative !important;
  // transform: none !important;

  &.f-module {
    display: revert !important;
    vertical-align: middle !important;
  }
}
.exclamationSign {
  background-image: none !important;
  // border: 1px solid #f64e60 !important;
}
.f-cardBody {
  padding: 0px !important;
}

.PhoneInput {
  // height: calc(1.5em + 0.5rem + 8px) !important;
  .PhoneInputCountry {
    border: 0 !important;
    height: calc(1.5em + 0.5rem - 6px) !important;
  }
  .PhoneInputInput {
    border: none !important;
    background: transparent !important;
    box-shadow: none !important;
    height: auto !important;
    &:focus {
      outline: none !important;
      background: transparent !important;
    }
  }
  &.PhoneInput--focus {
    border-color: rgba(#2d9cdb, 1) !important;
    input {
      border-color: rgba(#2d9cdb, 1) !important;
    }
  }
}

.borderRed {
  border: #f64e60 !important;
}

.invalid-datepicker-feedback {
  color: #f64e60 !important;
}
.f-important {
  color: white !important;
}
.f-backgroundColor {
  // background-image: linear-gradient(#3caea3, #3678a2) !important;
  background: $primary !important;
}
.apexcharts-menu-icon {
  display: none !important;
}

.product-action {
  // margin-top: 1.5rem;
  cursor: pointer;
  b {
    margin-left: 1rem;
  }
  :hover {
    background-color: #0000000d;
  }
}
.customer-table-actions {
  cursor: pointer;
  margin-top: 0.5rem;
}

.customer-table-actions-icon {
  cursor: pointer;
  text-align: right;
  margin-top: 0.5rem;
  span {
    color: #828282;
  }
}

.category-icon-bg {
  background-color: #f2f2f2;
  width: 100px;
  height: 99px;
  border-radius: 100%;
  .you-have-no-category {
    font-size: 25px;
    color: #828282;
    line-height: 27.9px;
  }
}

.dragging-row {
  // display: table-row; /* Ensures the dragging element looks like a table row */
  // background-color: #f8f9fa; /* Light gray to match Bootstrap hover style */
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for visual feedback */
  // border: 1px solid #dee2e6;
  opacity: 0.7;
}

.search-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  border-radius: 5px;
  position: absolute;
  top: -10px;
  box-shadow: 0px 4px 4px 0px #00000040;
  width: 300px !important;
  background-color: #ffffff !important;
  input {
    width: 100%;
    border: none !important;
    ::placeholder {
      color: #bdbdbd;
    }

    color: #333333 !important;
    caret-color: #2d9cdb !important;
  }
  .close-icon {
    width: 14px;
    height: 14px;
  }
}
.position-left {
  left: 0 !important;
}
.position-right {
  right: 0 !important;
}
.sort-icon-rotate {
  transform: rotate(180deg) !important;
}
.table-width {
  width: 745px;
}
@media screen and (max-width: 780px) {
  .table-width {
    width: auto !important;
  }
  .whitespace-nowrap {
    white-space: nowrap !important;
  }

  .subscribe-card-height {
    height: 110px !important;
  }

  @media screen and (max-width: 420px) {
    .subscribe-card-height {
      height: auto !important;
    }
  }
  @media screen and (max-width: 388px) {
    .change-plan-button {
      padding-top: 2rem !important;
    }
  }
  @media screen and (max-width: 532px) {
    .no-display-mobile {
      display: none !important;
    }
    .show-desktop {
      display: none !important;
    }
    .no-display-desktop {
      display: block !important;
      text-align: right !important;
      width: 100% !important;
      margin-top: -1rem !important;
    }
    .display-mobile {
      display: block !important;
    }
  }
  @media screen and (max-width: 413px) {
    .no-display-desktop {
      margin-top: -2rem !important;
    }
  }

  .change-plan-button {
    padding-top: 0rem;
  }

  .no-display-mobile {
    display: flex;
    justify-content: space-between !important;
  }
  .no-display-desktop {
    display: none;
  }
  .display-mobile {
    display: none;
  }

  .show-desktop {
    display: block;
  }
}

.my-toggle-dropdown::after {
  display: none !important;
}
.invoice-delete-modal {
  .modal-dialog {
    max-width: 300px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    .invoice-body {
      border-radius: 10px !important;
      font-size: 16px;
    }
    .modal-footer {
      font-size: 14px !important;
    }
  }
}

.modal .modal-header .modal-title {
  .custom-text-invoice {
    color: #3f4254 !important;
  }
}

.custom-footer {
  position: static;
}

@media screen and (max-width: 842px) {
  .custom-footer {
    position: fixed !important;
    bottom: env(safe-area-inset-bottom, 0) !important;
    padding-bottom: env(safe-area-inset-bottom, 0);
    width: 100% !important;
  }
  .footer-padding {
    height: 25px;
  }
  @supports (-webkit-touch-callout: none) {
    .custom-footer {
      position: fixed;
    }
  }
}
