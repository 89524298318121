@import "./variables";

.header-mobile {
  // background-image: linear-gradient(#3caea3, #3678a2) !important;
  background: $themePrimaryColor !important;
  .brand-logo {
    img {
      max-width: 150px;
    }
  }
}
.brand {
  padding: 0 !important;
}
.brand-logo {
  img {
    max-width: 200px;
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(187deg)
      brightness(121%) contrast(114%);
    stroke: #ffffff;
  }
}
.header {
  // background-image: linear-gradient(#3caea3, #3678a2) !important;
  background: $themePrimaryColor !important;
  .header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      margin: 0;
      color: $light;
      &.upgrade-acc {
        display: flex;
        align-items: center;
      }
      .btn {
        margin-left: 0.5rem;
        padding: 0.5rem 1.5rem;
        &:hover {
          color: $themePrimaryColor !important;
          box-shadow: $boxShadowHoverBase;
        }
      }
    }

    @media screen and (max-width: 1500px) {
      .upgrade-acc {
        margin-left: auto;
        margin-right: 2rem;
        font-size: 1rem;
        button {
          margin-right: auto;
        }
      }
    }

    @media screen and (max-width: 1200px) {
      padding: 0.5rem 2rem !important;
      .upgrade-acc {
        margin-left: auto;
        margin-right: 2rem;
        span {
          display: none;
        }
        button {
          margin-right: none;
        }
      }
    }

    @media screen and (max-width: 991px) {
      display: none !important;
    }
  }
  .topbar {
    display: flex;
    align-items: center;
    .btn {
      background: none !important;
      box-shadow: none !important;
    }
    .dropdown-menu {
      top: 10px !important;
      padding: 0;
      border-top: 1px solid $themeSecondaryColor;
      border-radius: 0;
      ul {
        padding: 0 !important;
        li {
          a {
            padding: 0.5rem 1rem !important;
          }
        }
      }
    }
  }
}
