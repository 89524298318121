$light: #ffffff;
$dark: #000000;
$gray: #e9e9e9;
$themeColor: #f2f3f8;
$themePrimaryColor: #2d9cdb;
$themeSecondaryColor: #36a39c;
$textPrimaryColor: #333333;
$textSecondaryColor: #47525c;
$textLightColor: #6c8294;
$colorGreen: #6fcf97;
$colorOrange: #f2994a;
$colorRed: #eb5757;
$colorPurple: #bb6bd9;
$colorGray: #f0f3ff;
$borderColor: #ebedf2;

$borderRadiusBase: 4px;
$boxShadowHoverBase: 0 0 5px 0.25px rgba(0, 0, 0, 0.15);
$transition: 0.25s ease;
