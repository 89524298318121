/* style.scss */
.sidebar-dialog {
  position: absolute;
  top: 615px;
  // right: 23px;
  width: 220px;
  max-height: 400px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: none;
}

.sidebar-dialog.open {
  display: block;
}
.sidebar-dialog.ltr {
  right: 23px;
}
.sidebar-dialog.rtl {
  left: 23px;
}

//   .sidebar-content {
//     padding: 16px;
//   }

.columns-list {
  margin-top: 3px;
  text-transform: capitalize;
}
.columns-list.ltr {
  margin-left: 8px;
}
.columns-list.rtl {
  margin-right: 8px;
}

.column-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.letter-spacing-normal {
  letter-spacing: normal !important;
}
