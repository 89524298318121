@import "./variables";

.card-listitem {
  margin-top: 2rem;
  margin-bottom: 0;

  li {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebedf2;
    padding: 1rem 0.5rem;
    .card-icon {
      margin-right: 1rem;
      float: left;
      &.category {
        width: 1.25em;
        height: 1.25em;
      }
      &.discount {
        width: 3em;
        height: 3em;
      }
    }
    .card-info {
      h5 {
        color: $textSecondaryColor;
        font-weight: 500;
        margin: 0;
      }
      small {
        color: $textLightColor;
      }
    }
    .card-actions {
      .action {
        margin-left: 2rem;
        font-size: 0.75rem;
        &.action-edit {
          i {
            color: #2d9cdb;
          }
        }
        &.action-delete {
          i {
            color: #ed553b;
          }
        }
      }
    }
  }
}
