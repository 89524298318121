@import "./variables";
@import "./auth";
@import "./header";
@import "./sideBars";
@import "./drawers";
@import "./forms";
@import "./cards";

.html-invoice-mobile {
  background-color: white;
  height: 100%;
  .header {
    height: 5.5rem;
  }

  .header-img {
    padding-left: 2rem;
    padding-top: 1rem;
  }
  .header-img-company {
    text-align: center;
    padding-top: 1rem;
  }
  .company-detail {
    text-align: center;
    font-size: 20px;
    text-align: center;
  }
  .tax-invoice {
    padding-top: 30px;
    font-size: 40px;
    text-align: center;
  }
  .customer-information {
    padding-top: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
    .customer-name {
      padding-top: 6px;
      text-align: center;
      background-color: #f2f2f2;
      height: 3rem;
      border-radius: 5px;
      b {
        padding-left: 11px;
        padding-top: 9px;
        font-size: 19px;
      }
    }
    .customer-detail {
      padding-top: 13px;
      padding-left: 5px;
      font-size: 15px;
      font-weight: 500;
      .detail {
        float: left;
      }
      .title {
        width: 6rem;
        float: left;
      }
    }
  }
  .invoice-information {
    padding-top: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
    .invoice-name {
      padding-top: 6px;
      text-align: center;
      background-color: #f2f2f2;
      height: 3rem;
      border-radius: 5px;
      b {
        padding-left: 11px;
        padding-top: 9px;
        font-size: 19px;
      }
    }
    .invoice-detail {
      padding-left: 5px;
      padding-top: 13px;
      font-size: 15px;
      font-weight: 500;
      .detail {
        float: left;
      }
      .title {
        width: 8rem;
        float: left;
      }
    }
  }
  .table-div {
    max-width: 100%;
    overflow-x: auto;
    padding-left: 23px;
    padding-top: 40px;
    .table {
      border-top: 1px solid #ebedf3;
      height: 100px;
    }
  }
  .total-information {
    padding-top: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
    .total-name {
      padding-top: 6px;
      text-align: center;
      background-color: #f2f2f2;
      height: 3rem;
      border-radius: 5px;
      b {
        padding-left: 11px;
        padding-top: 9px;
        font-size: 19px;
      }
    }
    .total-detail {
      font-size: 15px;
      padding-top: 9px;
      font-weight: 400;
      .title {
        width: 14rem;
        float: left;
        padding-top: 8px;
      }
      .detail {
        float: right;
        padding-top: 8px;
      }
    }
  }
  .payment-information {
    padding-top: 10rem;
    padding-left: 2rem;
    padding-right: 2rem;
    .payment-name {
      padding-top: 6px;
      text-align: center;
      background-color: #f2f2f2;
      height: 3rem;
      border-radius: 5px;
      b {
        padding-left: 11px;
        padding-top: 9px;
        font-size: 19px;
      }
    }
    .payment-detail {
      padding-top: 9px;
      font-size: 15px;
      font-weight: 400;
      .detail {
        float: right;
      }
      .title {
        float: left;
      }
      .due {
        font-weight: 500;
      }
    }
    .payment-border {
      border: 1px solid;
      border-color: #e0e0e0;
      padding: 10px 17px;
    }
    .payments {
      padding-top: 30px;
      .date {
        color: #828282;
        font-size: 13px;
      }
      .method {
        padding-left: 1rem;
        font-size: 13px;
        color: #828282;
      }
      .amount {
        padding-left: 2rem;
        color: #828282;
        float: right;
        font-size: 13px;
      }
    }
  }
  .terms-information {
    padding-top: 5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    .header-name {
      padding-top: 6px;
      text-align: center;
      background-color: #f2f2f2;
      height: 3rem;
      border-radius: 5px;
      b {
        padding-left: 11px;
        padding-top: 9px;
        font-size: 19px;
      }
    }
    .terms-name {
      background-color: #f2f2f2;
      height: 3rem;
      display: flex;
      border-radius: 5px;
      b {
        padding-left: 11px;
        padding-top: 9px;
        font-size: 16px;
      }
    }
    .terms-detail {
      padding-left: 9px;
      font-size: 15px;
      padding-top: 9px;
      font-weight: 500;
      a {
        word-break: break-all;
      }
    }
  }
  .terms-border {
    border: 1px solid;
    border-color: #e0e0e0;
    padding: 2px 0px;
  }
  .terms-border2 {
    border: 1px solid;
    border-color: #e0e0e0;
    padding: 2px 0px;
  }
  .qr-img {
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 5rem;
  }
}
