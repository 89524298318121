.login-form {
  padding: 15svh 0;
  @supports not (padding: 15svh) {
    padding: 15svh; // Fallback for browsers that don’t support `svh`
  }
  &:after {
    content: "";
    background: white;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
  &.login-signin {
    .form-title {
      text-align: left;
      margin-bottom: 1rem;
    }
    .form-wrap {
      max-width: 450px;
      margin: auto;

      .form-control {
        &.is-valid {
          background-image: none;
        }
      }
    }
    h1 {
      color: #828282;
      font-weight: 300;
    }
    p {
      color: #828282;
      max-width: 440px;
      margin: 2rem auto;
    }
    .illustration {
      text-align: center;
      .img-wrap {
        margin: 0 auto 2rem;
        max-width: 450px;
        img {
          width: 100%;
        }
      }
    }
  }
  .are-you {
    text-align: center;
    .what-are-you {
      display: flex;
      justify-content: center;
      .account-type {
        margin: 2rem 1rem;
        .card {
          min-width: 200px;
          width: 200px;
          height: 200px;
          background: #f2f2f2;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          border: 0;
          margin: 0 !important;
          svg {
            height: 60px;
          }
          p {
            color: #828282;
          }
        }
        &.active {
          .card {
            background: $themePrimaryColor;
            svg {
              fill: white !important;
              color: white;
              filter: invert(99%) sepia(99%) saturate(0%) hue-rotate(0deg) brightness(200%) contrast(100%);
            }
            p {
              color: white;
            }
          }
        }
      }
    }
  }
  &.user-activation {
    .text-block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 55svh;
      color: #4f4f4f;
      h6 {
        max-width: 400px;
        margin: 0 auto 2rem;
        color: #4f4f4f;
        font-weight: 400;
      }
      p {
        color: #4f4f4f;
        font-weight: 400;
      }
      a {
        color: $themePrimaryColor;
      }
    }
  }
  .already-account-text {
    color: #333333;
    font-size: 12px;
    font-weight: 500;
  }
  .select-language {
    box-shadow: 0px 4px 8px 3px #00000026 !important;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    border-radius: 5px;
    width: 100px !important;
    .boder-radius-top {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    .boder-radius-bottom {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    li {
      list-style-type: none;
      width: 100% !important;
      box-shadow: 0px 4px 8px 3px #00000026 !important;
      &.active {
        background-color: #f2f2f2;
        box-shadow: none !important;
      }
    }
  }
  .auth-btn {
    width: auto;
    font-weight: 500;
    font-size: 16px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2d9cdb;
    margin-top: 2rem !important;
    margin-bottom: 0px !important;
    color: #ffffff;
    &:disabled {
      background: #e0e0e0 !important;
      color: #4f4f4f !important;
    }
  }
}
.auth-heading-main {
  color: #333333;
  font-size: 18px;
}
.auth-signup-margin {
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 480px) {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
.auth-login-reset-center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
