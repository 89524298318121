@import "./variables";
@import "./auth";
@import "./header";
@import "./sideBars";
@import "./drawers";
@import "./forms";
@import "./cards";

.html-invoice {
  background-color: white;
  .header-row {
    padding-left: 2rem;
  }
  .header {
    background-color: #2d9cdb;
  }
  .header-img {
    padding-left: 3rem;
    padding-top: 1rem;
  }
  .icon-logo {
    padding-top: 5rem;
    padding-left: 0px;
  }
  .company-detail {
    padding-top: 4.4rem;
    padding-left: 6.7rem;
    font-size: 15px;
  }
  .tax-invoice {
    white-space: nowrap;
    font-size: 30px;
    padding-left: 10rem;
  }
  .refund-invoice {
    white-space: nowrap;
    font-size: 30px;
    padding-left: 6rem;
  }
  .invoice-info {
    padding-left: 6rem;
    padding-right: 4rem;
  }
  .info-row {
    padding-top: 3rem;
  }
  .customer-information {
    .customer-name {
      background-color: #f2f2f2;
      height: 3rem;
      display: flex;
      border-radius: 5px;
      b {
        padding-left: 11px;
        padding-top: 9px;
        font-size: 16px;
      }
    }
    .customer-detail {
      padding-left: 9px;
      font-size: 15px;
      padding-top: 9px;
      font-weight: 500;
      .detail {
        float: left;
      }
      .title {
        width: 6rem;
        float: left;
      }
    }
  }
  .invoice-information {
    .invoice-name {
      border-radius: 5px;
      background-color: #f2f2f2;
      height: 3rem;
      display: flex;
      b {
        padding-left: 11px;
        padding-top: 9px;
        font-size: 16px;
      }
    }
    .invoice-detail {
      padding-left: 9px;
      font-size: 15px;
      padding-top: 9px;
      font-weight: 500;
      .detail {
        float: left;
      }
      .title {
        width: 8rem;
        float: left;
      }
    }
  }
  .table {
    margin-top: 30px;
  }
  .total-information {
    .total-name {
      border-radius: 5px;
      background-color: #f2f2f2;
      height: 3rem;
      display: flex;
      b {
        padding-left: 11px;
        padding-top: 9px;
        font-size: 16px;
      }
    }
    .total-detail {
      font-size: 15px;
      padding-top: 9px;
      font-weight: 400;
      .title {
        width: 15rem;
        float: left;
        padding-top: 8px;
      }
      .detail {
        float: right;
        padding-top: 8px;
      }
    }
  }
  .payment-information {
    .payment-name {
      border-radius: 5px;
      background-color: #f2f2f2;
      padding: 7px;
      b {
        padding-left: 11px;
        padding-top: 9px;
        font-size: 16px;
      }
    }
    .payment-detail {
      padding-top: 9px;
      font-size: 15px;
      font-weight: 400;
      .detail {
        float: right;
      }
      .title {
        float: left;
      }
      .due {
        font-weight: 500;
      }
    }
    .payment-border {
      border: 1px solid;
      border-color: #e0e0e0;
      padding: 10px 17px;
    }
  }
  .payments {
    padding-top: 30px;
    .date {
      color: #828282;
      font-size: 13px;
    }
    .method {
      padding-left: 1rem;
      font-size: 13px;
      color: #828282;
    }
    .amount {
      padding-left: 2rem;
      color: #828282;
      float: right;
      font-size: 13px;
    }
  }
  .table {
    padding-top: 2rem;
    width: 100% !important;
  }
  .terms-information {
    .header-name {
      background-color: #f2f2f2;
      height: 3rem;
      display: flex;
      border-radius: 5px;
      b {
        padding-left: 11px;
        padding-top: 9px;
        font-size: 16px;
      }
    }
    .terms-name {
      background-color: #f2f2f2;
      height: 3rem;
      display: flex;
      border-radius: 5px;
      b {
        padding-left: 11px;
        padding-top: 9px;
        font-size: 16px;
      }
    }
    .terms-detail {
      padding-left: 9px;
      font-size: 15px;
      padding-top: 9px;
      font-weight: 500;
    }
  }
  .terms-border {
    border: 1px solid;
    border-color: #e0e0e0;
    padding: 2px 0px;
  }
  .terms-border2 {
    border: 1px solid;
    border-color: #e0e0e0;
    padding: 2px 0px;
  }
  .terms-info-row {
    padding-right: 2rem;
  }
  .footer {
    padding-top: 30rem;
    padding-bottom: 15px;
    .invoice-number {
      color: #828282;
    }
    .company-icon {
      display: flex;
      justify-content: center;
    }
    .date-time {
      color: #828282;
      text-align: right;
      // display: flex;
      // flex-direction: column;
      // justify-content: right;
      margin-left: 6rem;
      .time {
        padding-left: 20px;
      }
    }
  }
}
