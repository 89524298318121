@import "./variables";
@import "./auth";
@import "./header";
@import "./sideBars";
@import "./drawers";
@import "./forms";
@import "./cards";

@media screen and (min-width: 1800px) {
  .pos-container {
    .container {
      padding: 0 450px !important;
    }
  }
}
@media screen and (min-width: 630px) {
  .pos-container {
    .container {
      padding: 0 163px;
    }
  }
}
@media screen and (min-width: 830px) {
  .pos-container {
    .container {
      padding: 0 193px;
    }
  }
}

@media screen and (min-width: 930px) {
  .pos-container {
    .container {
      padding: 0 300px;
    }
  }
}

.pos-invoice {
  align-items: center;
  text-align: center;
  background: #ffffff;
  padding: 3px 17px;
  .bold-color {
    color: #333333;
  }
  .light-color {
    color: #828282;
  }
  .pos-invoice-cashier {
    display: flex;
    justify-content: space-between;
  }
  .doted-line {
    border: 0.613134px dashed #e0e0e0;
  }
  .pos-qrcode {
    margin-top: 5%;
    text-align: center;
  }
}
